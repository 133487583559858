import * as yup from 'yup';
import * as React from 'react';
import { Formik } from 'formik';
import { useMediaQuery } from 'react-responsive';
import { gql } from '@apollo/client';
import { useMutation } from 'react-query';
import { majorScale, toaster } from 'evergreen-ui';
import {
  HTTP_REQ_STATUS,
  VIEWPORT_BREAKPOINTS,
  AUTH_VIEW,
} from '../../../../helpers/enums';
import Btn from '../../../../components/btn/btn';
import Container from '../../../../components/container/container';
import FormInput from '../../../../components/form-input/form-input';
import { getErrorMessage } from '../../../../helpers/functions';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { SuperAffiliateContext } from '../../../../providers/super-affiliate';
import apiManager from '../../../../helpers/apiManager';

const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestTokenMutation($email: String!, $SuperCompanyId: String) {
    reset_password(email: $email, SuperCompanyId: $SuperCompanyId) {
      message
    }
  }
`;

const StyledForm = styled.form`
  label {
    font-weight: bold;
  }
`;

const REQUEST_TOKEN_FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
});

function RequestToken({
  setCurrentAuthView,
  increaseStep,
  email,
  setIsResetPasswordMessage,
  resetPasswordMessage,
}) {
  // const [mutate] = useMutation(REQUEST_TOKEN_MUTATION);

  const { mutateAsync: resetYourPassword } = useMutation(
    apiManager.ResetPassword
  );

  const [status, setStatus] = React.useState(HTTP_REQ_STATUS.idle);
  const { superAffiliate } = React.useContext(SuperAffiliateContext);

  async function handleRequestToken(values) {
    try {
      setStatus(HTTP_REQ_STATUS.loading);

      const { data, message } = await resetYourPassword({
        ...values,
        superCompanyId: superAffiliate.superAffiliateUserId,
      });

      if (data) {
        setStatus(HTTP_REQ_STATUS.success);

        toaster.success(message, {
          id: 'email-success',
          description:
            'Please check your email inbox for a password reset token.',
        });

        increaseStep();
        if (resetPasswordMessage) {
          setIsResetPasswordMessage(false);
        }
      }
    } catch (error) {
      toaster.danger(error);
      setStatus(HTTP_REQ_STATUS.error);
    }
  }

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <Formik
      validationSchema={REQUEST_TOKEN_FORM_SCHEMA}
      initialValues={{ email: email ? email : '' }}
      onSubmit={handleRequestToken}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => {
        return (
          <>
            <StyledForm onSubmit={handleSubmit}>
              <FormInput
                marginBottom={majorScale(2)}
                inputHeight={majorScale(5)}
                label="Email *"
                inputMode="email"
                name="email"
                placeholder="Enter email"
                value={values.email}
                isInvalid={touched.email && !!errors.email}
                validationMessage={touched.email && errors.email}
                onChange={handleChange}
                disabled={email}
              />

              <Link to="/signin" marginBottom={majorScale(2)} textAlign="right">
                <Btn
                  fontSize="1em"
                  look="primary-minimal"
                  paddingX={0}
                  type="button"
                  onClick={() => setCurrentAuthView(AUTH_VIEW.SIGNIN.value)}
                >
                  I remember my password now.
                </Btn>
              </Link>

              <Container maxWidth={isLaptop ? 300 : 'unset'}>
                <Btn
                  type="submit"
                  width="100%"
                  marginY={majorScale(3)}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="1em"
                  isLoading={status === HTTP_REQ_STATUS.loading}
                >
                  Request Password Link
                </Btn>
              </Container>
            </StyledForm>
          </>
        );
      }}
    </Formik>
  );
}

export default RequestToken;
