import * as React from 'react';
import get from 'lodash/get';
import { gql, useSubscription } from '@apollo/client';

import { SuperAffiliateContext } from '../../providers/super-affiliate';
import LoadingStripe from '../../components/loading-state-spinner/laoding-stripe';
import MaintenancePage from '../../pages/maintenance-page/maintenance-page';
import useTokenExpiry from '../../hooks/use-token-expiry';
import { convertToBoolean } from '../../helpers/functions';
import { wsClient } from '../../libs/apollo-client';

const MAINTENANCE_SUBSCRIPTION = gql`
  subscription {
    onPublished {
      id
      status
    }
  }
`;

function WidgetProtectedRoute({ component: Component }) {
  useTokenExpiry();
  const { superAffiliate, loading, siteMaintenancePage } = React.useContext(
    SuperAffiliateContext
  );
  const [isMaintenance, setMaintenance] = React.useState(false);
  const { loading: maintenanceLoading } = useSubscription(
    MAINTENANCE_SUBSCRIPTION,
    {
      onError: () => {
        setMaintenance(true);
      },
      onSubscriptionData: ({ subscriptionData }) => {
        setMaintenance(get(subscriptionData, 'data.onPublished.status', false));
      },
    }
  );

  // React.useEffect(() => {
  //   const handleWebSocketError = () => {
  //     setMaintenance(true);
  //   };
  //   wsClient.onError(handleWebSocketError);
  // }, [wsClient]);

  if (window.location.pathname === '/widget-web-app') {
    return <div>status: 'OK' </div>;
  }

  if (convertToBoolean(process.env.REACT_APP_MANITENANCE_PAGE)) {
    return <MaintenancePage />;
  }

  if ((maintenanceLoading && !isMaintenance) || loading) {
    return <LoadingStripe />;
  }

  if (isMaintenance) {
    return <MaintenancePage />;
  }

  if (!loading && siteMaintenancePage && !superAffiliate.superAffiliateUserId) {
    return <MaintenancePage />;
  }
  // when add username and passoword then use !superAffiliate.isWidgetEnabled here
  if (
    !loading &&
    (!superAffiliate.superAffiliateUserId || !!superAffiliate.isWidgetEnabled)
  ) {
    return <div>Not Found</div>;
  }
  // when add username and passoword then use !!superAffiliate.isWidgetEnabled here
  if (
    !loading &&
    (!superAffiliate.isWidgetEnabled || !!superAffiliate.superAffiliateUserId)
  ) {
    return Component;
  }

  return <LoadingStripe />;
}

export default WidgetProtectedRoute;
