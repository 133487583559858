export const tiktokEvent = (action, params) => {
  if (
    window.ttq &&
    typeof window.ttq !== 'undefined' &&
    typeof window.ttq.track === 'function'
  ) {
    window.ttq.track(action, params);
  } else {
    console.warn('TikTok Analytics ttq function is not available.');
  }
};
