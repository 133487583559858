import React from 'react';
import ticketsirLogo from '../assets/images/ticketsir-logo.png';
import _ from 'lodash';
import { VerifySuperAffiliateUser } from '../helpers/maintenance-utils';

const SuperAffiliateContext = React.createContext();

const superAffiliateTemp = {
  businessName: 'Ticketsir',
  logo: ticketsirLogo,
  description: 'Never miss a moment',
  domainName: 'cokobar.com',
  isWidgetEnabled: false,
  banner: [
    'https://res.cloudinary.com/dprzucacc/image/upload/v1638435615/ticketsir_admin_sitebox_photos/unsplash_8XLapfNMW04_1_dnzinb.png',
  ],
  withoutHeader: true,
};

export let superAffiliateObject = {};

function SuperAffiliateProvider(props) {
  const { credentials } = props;

  const [loading, setLoading] = React.useState(false);
  const [siteMaintenancePage, setSiteMaintenancePage] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(superAffiliateObject);

  const getSuperAffiliateDetails = async () => {
    setLoading(true);

    try {
      const response = await VerifySuperAffiliateUser(credentials);
      const responseData = _.get(response, 'data.data', {});
      const updatedData = {
        businessName: _.get(responseData, 'businessName', superAffiliateTemp.businessName),
        description: _.get(responseData, 'description', superAffiliateTemp.description),
        isWidgetEnabled: _.get(responseData, 'isWidgetEnabled', superAffiliateTemp.isWidgetEnabled),
        logo: _.get(responseData, 'logo', superAffiliateTemp.logo),
        theme: _.get(responseData, 'theme', superAffiliateTemp.theme),
        themeButton: _.get(responseData, 'themeButton', superAffiliateTemp.themeButton),
        superAffiliateUserId: _.get(responseData, 'superAffiliateUserId', null),
        banner: _.isEmpty(_.get(responseData, 'banner')) ? superAffiliateTemp.banner : responseData.banner,
        domainName: _.get(responseData, 'domainName', superAffiliateTemp.domainName),
        withoutHeader: superAffiliateTemp.withoutHeader,
      };

      setData(updatedData);

      // for use outside react components
      superAffiliateObject = updatedData;

      setLoading(false);
      setSiteMaintenancePage(_.isNull(updatedData.superAffiliateUserId));
    } catch (error) {
      setLoading(false);
      setError(true);
      setSiteMaintenancePage(true);
    }
  };

  React.useEffect(() => {
    getSuperAffiliateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = React.useMemo(
    () => ({ superAffiliate: data, loading, error, siteMaintenancePage }),
    [data, loading, error, siteMaintenancePage]
  );

  return <SuperAffiliateContext.Provider value={value} {...props} />;
}

export { SuperAffiliateProvider as default, SuperAffiliateContext };
