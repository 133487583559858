import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Pane,
  Heading,
  ListItem,
  Paragraph,
  UnorderedList,
  minorScale,
  majorScale,
} from 'evergreen-ui';

import Container from '../container/container';
import Header from '../header/header';
import Footer from '../footer/footer';
import { SuperAffiliateContext } from '../../providers/super-affiliate';

function EventDetailsLoader() {
  const {
    superAffiliate: { withoutHeader },
  } = React.useContext(SuperAffiliateContext);
  return (
    <>
      <Pane height="65vh" overflowY="hidden" position="relative">
        <Skeleton width="100%" height="100%" />
        <Pane
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          display="flex"
          flexDirection="column"
          backgroundColor="var(--secondary-dark-color)"
        >
          {withoutHeader && (
            <Container
              maxWidth="100%"
              paddingTop={minorScale(3)}
              paddingBottom={minorScale(5)}
            >
              <Header />
            </Container>
          )}

          <Container maxWidth="100%" height="100%" paddingTop={majorScale(1)}>
            <Pane
              paddingX={majorScale(3)}
              paddingY={majorScale(8)}
              height="inherit"
              display="flex"
              flexDirection="column"
              color="#fff"
              fontSize="1rem"
            >
              <Heading
                is="h1"
                marginTop="auto"
                marginBottom={majorScale(2)}
                color="inherit"
                fontSize="1.85em"
                fontWeight={900}
              >
                <Skeleton width={360} />
              </Heading>
              <Paragraph
                marginBottom={majorScale(1)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
                columnGap={majorScale(1)}
              >
                <Skeleton width={180} />
              </Paragraph>
              <Paragraph
                marginBottom={majorScale(1)}
                display="flex"
                alignItems="center"
                color="inherit"
                fontSize="1.225em"
                columnGap={majorScale(1)}
              >
                <Skeleton width={180} />
              </Paragraph>
            </Pane>
          </Container>
        </Pane>
      </Pane>

      <Container
        is="section"
        maxWidth={960}
        paddingY={majorScale(2)}
        columnGap={majorScale(2)}
        color="var(--black-light-color)"
        fontSize="1rem"
      >
        <Skeleton width="80%" />
      </Container>

      <Pane
        is="section"
        fontSize="1rem"
        borderTop="1px solid var(--black-lightest-color)"
        borderBottom="1px solid var(--black-lightest-color)"
      >
        <Container maxWidth="100%" position="relative">
          <UnorderedList
            display="flex"
            marginLeft={0}
            columnGap={majorScale(2)}
            color="var(--black-lighter-color)"
          >
            {Array(6)
              .fill()
              .map((_, i) => (
                <ListItem
                  key={i}
                  marginY={0}
                  paddingY={majorScale(2)}
                  fontSize="1em"
                  cursor="pointer"
                  color="var(--black-lighest)"
                >
                  <Skeleton width={120} />
                </ListItem>
              ))}
          </UnorderedList>

          <Pane
            width={420}
            position="absolute"
            top="0"
            right="0"
            backgroundColor="#fff"
            elevation={2}
          >
            <Paragraph
              paddingX={majorScale(4)}
              paddingY={majorScale(2)}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              color="var(--black-light-color)"
              fontSize="1em"
              lineHeight={1.1}
              borderBottom="1px solid var(--black-lightest-color)"
            >
              Price
              <Skeleton width={120} />
            </Paragraph>

            <Pane
              paddingX={majorScale(4)}
              paddingY={majorScale(2)}
              borderBottom="1px solid rgba(28, 28, 28, 0.15)"
            >
              <Skeleton width="100%" height={32} />
            </Pane>

            <Paragraph
              paddingX={majorScale(4)}
              paddingY={majorScale(2)}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              color="var(--gray-lightest-color)"
            >
              {Array(4)
                .fill()
                .map((item, i) => (
                  <Skeleton key={i} width={30} height={15} />
                ))}
            </Paragraph>
          </Pane>
        </Container>
      </Pane>

      <Container maxWidth="100%" paddingY={minorScale(0)}>
        <Pane
          is="section"
          maxWidth={840}
          fontSize="1rem"
          color="var(--black-light-color)"
        >
          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Description
          </Heading>

          <Paragraph color="inherit" fontSize="1em">
            <Skeleton count={10} />
          </Paragraph>

          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Date and Time
          </Heading>

          <Paragraph
            display="flex"
            alignItems="center"
            columnGap={majorScale(2)}
            color="inherit"
            fontSize="1em"
          >
            {Array(2)
              .fill()
              .map((_, i) => (
                <Skeleton key={i} width={150} height={60} />
              ))}
          </Paragraph>

          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Photos
          </Heading>

          <Pane
            display="flex"
            flexWrap="wrap"
            columnGap={majorScale(3)}
            rowGap={majorScale(3)}
          >
            {Array(3)
              .fill()
              .map((_, i) => (
                <Skeleton key={i} width={240} height={240} />
              ))}
          </Pane>

          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Location
          </Heading>

          <Paragraph
            marginBottom={majorScale(2)}
            color="inherit"
            fontWeight={700}
            fontSize="1em"
          >
            <Skeleton width={120} />
          </Paragraph>

          <Paragraph
            marginBottom={majorScale(1)}
            color="inherit"
            fontSize="1em"
          >
            <Skeleton width={480} />
          </Paragraph>

          <Skeleton width={720} height={360} />

          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            About the host
          </Heading>

          <Paragraph
            marginBottom={majorScale(1)}
            color="inherit"
            fontSize="1em"
          >
            <Skeleton count={5} />
          </Paragraph>

          <Skeleton width={90} />

          <Heading
            marginTop={majorScale(5)}
            marginBottom={majorScale(2)}
            color="var(--text-default-color)"
            fontSize="1.5em"
            fontWeight={700}
          >
            Category
          </Heading>

          <UnorderedList
            display="flex"
            rowGap={majorScale(2)}
            columnGap={majorScale(2)}
          >
            {Array(4)
              .fill()
              .map((_, i) => (
                <Skeleton key={i} width={120} height={30} />
              ))}
          </UnorderedList>
        </Pane>
      </Container>

      {withoutHeader && <Footer />}
    </>
  );
}

export default EventDetailsLoader;
