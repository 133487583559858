import axios from 'axios';
import * as React from 'react';

import useCountriesHomePage, {
  defaultCountry,
} from '../hooks/use-countries-homepage';
import { SuperAffiliateContext } from './super-affiliate';

const UserIpAddressContext = React.createContext();

function UserIpAddressProvider(props) {
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const { data } = useCountriesHomePage({
    variables: { superCompanyId: superAffiliate.superAffiliateUserId },
  });

  // default id of United Kingdom is used here
  const [locationId, setLocationId] = React.useState(9);

  const [ipAddress, setIpAddress] = React.useState('');

  // default array containing UK data
  const [countries, setCountries] = React.useState([defaultCountry]);

  React.useEffect(() => {
    getLocationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  React.useEffect(() => {
    if (data?.CountryDetails?.CountryDetails?.length) {
      setCountries(data.CountryDetails?.CountryDetails);
    }
  }, [data?.CountryDetails]);

  function getLocationId(location) {
    const country = countries.filter(
      (country) =>
        location === country.ISO2 || location === country.CountryDescription
    );
    if (country.length) setLocationId(country[0].CountryId);
    else setLocationId(9);
  }

  // fetch
  function getLocationDetails() {
    axios
      .get('https://ipapi.co/json/')
      .then((res) => {
        setIpAddress(res.data.ip);
        getLocationId(res.data.country_code);
      })
      .catch((err) => console.log(err));
  }

  const value = React.useMemo(
    () => ({ countries, locationId, getLocationId, ipAddress }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countries, locationId]
  );

  return <UserIpAddressContext.Provider value={value} {...props} />;
}

export { UserIpAddressProvider as default, UserIpAddressContext };
