import React from 'react';
import styled from '@emotion/styled';
import { minorScale, Pane } from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import { VIEWPORT_BREAKPOINTS } from '../../helpers/enums';
import Container from '../container/container';
import sampleEventCoverImage from '../../assets/images/img-event-1.png';

const EventImageBlurredBanner = styled(Pane)`
  background-color: var(--secondary-dark-color);
  filter: blur(1rem);
  background: ${(props) =>
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('${props.image}') no-repeat center`};
  background-size: 100% 100%;
  height: 100%;
`;

const EventImageBanner = styled(Pane)`
  height: ${(props) => props.height || '100%'};
  background-color: var(--secondary-dark-color);
  background: ${(props) =>
    `linear-gradient(to bottom, 
    rgba(217, 217, 217, 0) 16.51%, rgba(6, 48, 77, .8)), url('${props.image}') no-repeat center`};
  background-size: cover;

  @media screen and (max-width: ${VIEWPORT_BREAKPOINTS.sm}px) {
    background-image: ${(props) => `url('${props.image}')`};
  }
`;

export const EventBannerWithContainer = (props) => {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  return (
    <>
      <Pane height={`${width / 2}px`} overflowY="hidden" position="relative">
        <EventImageBlurredBanner image={props.image || sampleEventCoverImage} />

        <Pane
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          display="flex"
          flexDirection="column"
        >
          <Container maxWidth={1000} height="100%" paddingTop={minorScale(2)}>
            <EventImageBanner
              ref={ref}
              position="relative"
              height={`${width / 2}px`}
              image={props.image || sampleEventCoverImage}
              {...props}
            >
              {props.children}
            </EventImageBanner>
          </Container>
        </Pane>
      </Pane>
    </>
  );
};

export const EventBanner = (props) => {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(0);

  React.useLayoutEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  const isTabletMd = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  if (isTabletMd) {
    return (
      <Pane
        width="100%"
        height="100%"
        position="absolute"
        top="0"
        display="flex"
        flexDirection="column"
      >
        <Container maxWidth={1000} height="100%" paddingTop={minorScale(2)}>
          <EventImageBanner
            ref={ref}
            position="relative"
            height={`${width / 2}px`}
            image={props.image || sampleEventCoverImage}
          >
            {props.children}
          </EventImageBanner>
        </Container>
      </Pane>
    );
  }

  return (
    <Pane height={`${width / 2}px`} overflowY="hidden" position="relative">
      <EventImageBanner
        ref={ref}
        position="relative"
        height={`${width / 2}px`}
        image={props.image || sampleEventCoverImage}
      ></EventImageBanner>
    </Pane>
  );
};
