import * as React from 'react';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { Formik, Field } from 'formik';
import { gql } from '@apollo/client';
import { useMutation } from 'react-query';

import {
  Pane,
  Heading,
  Checkbox,
  Paragraph,
  toaster,
  minorScale,
  majorScale,
  Text,
  UnorderedList,
  ListItem,
  TickCircleIcon,
} from 'evergreen-ui';
import { useMediaQuery } from 'react-responsive';

import {
  getErrorMessage,
  optionsToReactSelectFormat,
  cleanSpaces,
} from '../../../helpers/functions';
import Btn from '../../../components/btn/btn';
import AppleAuth from '../apple-auth/apple-auth';
import GoogleAuth from '../google-auth/google-auth';
import FacebookAuth from '../facebook-auth/facebook-auth';
import Container from '../../../components/container/container';
import FormInput from '../../../components/form-input/form-input';
import { VIEWPORT_BREAKPOINTS, AUTH_VIEW } from '../../../helpers/enums';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import FormSelectBox from '../../../components/form-select-box/form-select-box';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import TermsAndConditions from '../../../components/terms-and-conditions/terms-and-conditions';
import useCountries from '../../../hooks/use-countries';
import FormPasswordInput from '../../../components/form-password-input/form-password-input';
import { SuperAffiliateContext } from '../../../providers/super-affiliate';
import useSubdomain from '../../../hooks/use-subdomain';
import apiManager from '../../../helpers/apiManager';

const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $countryId: Int
    $confirmPassword: String!
    $isSubscribed: Boolean!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      countryId: $countryId
      confirmPassword: $confirmPassword
      isSubscribed: $isSubscribed
    ) {
      message
    }
  }
`;

const SIGNUP_FORM_INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  countryId: '',
  password: '',
  confirmPassword: '',
  isSubscribed: false,
  acceptTerms: false,
};

const SIGNUP_FORM_SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required('First name is required')
    .max(30, 'First name can be at most 30 characters'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required')
    .max(30, 'Last name can be at most 30 characters'),
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  // postalCode: yup.string().trim(),
  // contactAddress: yup.string().trim().required('Address is required'),
  countryId: yup.number().required('Country is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .test('confirmPassword', 'Passwords must match', (value, context) => {
      return context.parent.password === value;
    }),
  isSubscribed: yup.boolean().default(false),
  acceptTerms: yup
    .boolean()
    .default(false)
    .required(`You must agree to TicketSir terms and conditions to continue`)
    .test(
      'must agree to terms and conditions',
      `You must agree to TicketSir terms and conditions to continue`,
      (value) => !!value
    ),
});

const StyledCheckbox = styled(Checkbox)`
  & > div {
    border: 2px solid var(--secondary-default-color);
  }

  & > input:focus + div,
  & > input:checked + div {
    box-shadow: none !important;
  }

  & > input:checked + div {
    background-image: none !important;
    background-color: var(--secondary-default-color) !important;
  }

  & > span {
    line-height: 1.2;
    color: var(--text-default-color);
    font-size: 0.875rem;
  }
`;
const StyledModal = styled(Pane)`
  margin-right: -30px !important;
  margin-left: -30px !important;
`;

const StyledHeading = styled(Heading)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    font-size: 1.75rem;
  }
`;

const StyledHeadingContainer = styled(Container)`
  padding-right: 20px;
  padding-bottom: 1rem;

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    max-width: 300px;
  }
`;

const StyledFormContainer = styled(Container)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    padding: 120px 0;
  }
`;

const Signup = ({ closeModal, setCurrentAuthView }) => {
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  // const [mutate] = useMutation(SIGNUP_MUTATION);
  const { data: countryList } = useCountries();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { hostname } = useSubdomain();

  const { mutateAsync: mutate } = useMutation(apiManager.UserRegistration);

  async function handleSignup(values) {
    try {
      setIsLoading(true);
      // const { data } = await mutate({ variables: { ...values } });

      const { data, message } = await mutate({
        ...values,
        registerType: 'email',
        SuperCompanyId: superAffiliate.superAffiliateUserId,
        isapplogin: false,
      });

      if (data) {
        toaster.success(message, {
          description:
            'Please check your email and follow the instruction to verify your account.',
        });
        closeModal();
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  }

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  return (
    <StyledModal className="row" position="relative">
      <Pane
        className="col-md-5"
        backgroundColor={!isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
      >
        <StyledHeadingContainer paddingY={majorScale(5)} maxWidth={400}>
          <Pane
            width={isLaptop ? 180 : 130}
            overflow="hidden"
            marginBottom={majorScale(5)}
          >
            <img
              src={superAffiliate.logo}
              alt="Logo"
              style={{ maxWidth: '100%' }}
            />
          </Pane>

          <StyledHeading
            marginBottom={minorScale(5)}
            fontWeight={900}
            fontSize="2rem"
          >
            Experience awesome events with {superAffiliate.businessName}.
          </StyledHeading>

          {isLaptop && (
            <>
              <SigninOption setCurrentAuthView={setCurrentAuthView} />
            </>
          )}
        </StyledHeadingContainer>
      </Pane>

      <Pane
        className="col"
        backgroundColor={isLaptop ? 'rgba(207, 216, 220, 0.15)' : '#fff'}
      >
        <StyledFormContainer maxWidth={420} paddingY={minorScale(15)}>
          {!hostname && (
            <Pane
              marginY={majorScale(2)}
              className="row w-100"
              justifyContent="space-between"
            >
              <Pane className="col-12 col-md-6">
                <GoogleAuth isSignup callback={closeModal} />
              </Pane>
              {/* hide facebook */}
              <Pane className="col-12 col-md-4" display="none">
                <FacebookAuth isSignup callback={closeModal} />
              </Pane>
              <Pane className="col-12 col-md-6">
                <AppleAuth isSignup callback={closeModal} />
              </Pane>
            </Pane>
          )}
          <Paragraph marginBottom={majorScale(2)} fontStyle="italic">
            Note: All fields are required
          </Paragraph>
          <Formik
            validationSchema={SIGNUP_FORM_SCHEMA}
            initialValues={SIGNUP_FORM_INITIAL_VALUES}
            onSubmit={(values) => {
              handleSignup(cleanSpaces(values));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="First Name *"
                    name="firstName"
                    placeholder="Enter first name"
                    value={values.firstName}
                    isInvalid={touched.firstName && !!errors.firstName}
                    validationMessage={touched.firstName && errors.firstName}
                    onChange={handleChange}
                  />

                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Last Name *"
                    name="lastName"
                    placeholder="Enter last name"
                    value={values.lastName}
                    isInvalid={touched.lastName && !!errors.lastName}
                    validationMessage={touched.lastName && errors.lastName}
                    onChange={handleChange}
                  />

                  <FormInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Email *"
                    inputMode="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                    validationMessage={touched.email && errors.email}
                    onChange={handleChange}
                  />

                  <Field name="countryId">
                    {({ field, meta, form }) => {
                      return (
                        <Pane marginBottom={majorScale(2)}>
                          <FormSelectBox
                            height={majorScale(5)}
                            label="Country *"
                            placeholder="Select country"
                            options={optionsToReactSelectFormat(
                              countryList?.CountryDetails?.CountryDetails,
                              {
                                label: 'CountryDescription',
                                value: 'CountryId',
                              }
                            )}
                            name={field.name}
                            value={field.value}
                            isInvalid={meta.touched && !!meta.error}
                            validationMessage={meta.touched && meta.error}
                            onChange={(selected) => {
                              form.setFieldValue(field.name, selected.value);
                            }}
                          />
                        </Pane>
                      );
                    }}
                  </Field>

                  <FormPasswordInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Password *"
                    name="password"
                    placeholder="Enter password"
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                    validationMessage={touched.password && errors.password}
                    onChange={handleChange}
                  />

                  <FormPasswordInput
                    marginBottom={majorScale(2)}
                    inputHeight={majorScale(5)}
                    label="Confirm Password *"
                    name="confirmPassword"
                    placeholder="Enter password again"
                    value={values.confirmPassword}
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                    validationMessage={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    onChange={handleChange}
                  />

                  <Pane
                    className="row"
                    marginRight="0 !important"
                    marginLeft="0 !important"
                  >
                    <UnorderedList className="col-md-6">
                      <ListItem
                        icon={<TickCircleIcon />}
                        iconColor={
                          values.password && /[A-Z]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        color={
                          values.password && /[A-Z]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        fontSize="1em"
                      >
                        Uppercase Character
                      </ListItem>

                      <ListItem
                        icon={<TickCircleIcon />}
                        iconColor={
                          values.password && /[a-z]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        color={
                          values.password && /[a-z]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        fontSize="1em"
                      >
                        Lowercase Character
                      </ListItem>

                      <ListItem
                        icon={<TickCircleIcon />}
                        iconColor={
                          values.password && /[0-9]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        color={
                          values.password && /[0-9]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        fontSize="1em"
                        marginBottom="0"
                      >
                        One Number
                      </ListItem>
                    </UnorderedList>
                    <UnorderedList className="col-md-6">
                      <ListItem
                        icon={<TickCircleIcon />}
                        iconColor={
                          values.password &&
                          /[^a-zA-Z0-9]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        color={
                          values.password &&
                          /[^a-zA-Z0-9]/.test(values.password)
                            ? 'success'
                            : 'muted'
                        }
                        fontSize="1em"
                      >
                        One Special Character
                      </ListItem>

                      <ListItem
                        icon={<TickCircleIcon />}
                        iconColor={
                          values.password && values.password.length >= 8
                            ? 'success'
                            : 'muted'
                        }
                        color={
                          values.password && values.password.length >= 8
                            ? 'success'
                            : 'muted'
                        }
                        fontSize="1em"
                      >
                        8 Characters Minimum
                      </ListItem>
                    </UnorderedList>
                  </Pane>

                  <Field name="isSubscribed">
                    {({ field, form, meta }) => {
                      return (
                        <StyledCheckbox
                          marginBottom={majorScale(2)}
                          fontSize="0.9rem"
                          name={field.name}
                          checked={field.value}
                          label={`I’d like to receive emails from ${superAffiliate.businessName} about upcoming events in my city, educational resources and updates.`}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  </Field>

                  <Field name="acceptTerms">
                    {({ field, form, meta }) => {
                      return (
                        <>
                          <StyledCheckbox
                            marginBottom={majorScale(2)}
                            fontSize="0.9rem"
                            name={field.name}
                            checked={field.value}
                            label={
                              <Text>
                                I Agree to ${superAffiliate.businessName}{' '}
                                <Text
                                  color="var(--primary-default-color)"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsModalOpen(true);
                                  }}
                                >
                                  Terms and Conditions
                                </Text>
                              </Text>
                            }
                            onChange={field.onChange}
                          />
                          <Pane
                            marginBottom={majorScale(3)}
                            display={
                              meta.touched && meta.error ? 'flex' : 'none'
                            }
                            columnGap={minorScale(2)}
                            color="#D14343"
                          >
                            <Paragraph
                              letterSpacing={0}
                              color="#D14343"
                              lineHeight="18px"
                              fontSize="13px"
                              fontWeight={400}
                              marginBottom={0}
                            >
                              {meta.touched && meta.error}
                            </Paragraph>
                          </Pane>
                        </>
                      );
                    }}
                  </Field>

                  {isModalOpen && (
                    <TermsAndConditions
                      isOpen={isModalOpen}
                      close={() => setIsModalOpen(false)}
                    />
                  )}

                  <Container className="px-0">
                    <Btn
                      type="submit"
                      width="100%"
                      marginY={majorScale(3)}
                      fontSize="1em"
                      isLoading={isLoading}
                    >
                      Create Account
                    </Btn>
                  </Container>
                </form>
              );
            }}
          </Formik>
        </StyledFormContainer>
      </Pane>

      {!isLaptop && (
        <Pane
          className="col-md-5"
          textAlign="center"
          paddingTop={minorScale(5)}
          paddingBottom={minorScale(9)}
          backgroundColor="rgba(207, 216, 220, 0.15)"
        >
          <SigninOption setCurrentAuthView={setCurrentAuthView} />
        </Pane>
      )}

      <CloseIcon
        onClick={() => {
          closeModal();
        }}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '2rem',
          top: '2.5rem',
        }}
      />
    </StyledModal>
  );
};

export default Signup;

export const SigninOption = ({ setCurrentAuthView }) => {
  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });
  return (
    <>
      <Paragraph
        marginBottom={minorScale(5)}
        color="var(--text-default-color)"
        fontSize="1rem"
      >
        Already have an account?
      </Paragraph>

      <Pane
        display={isLaptop ? 'block' : 'flex'}
        justifyContent={isLaptop ? 'unset' : 'center'}
      >
        <Btn
          type="button"
          fontSize="1em"
          look="secondary-filled"
          columnGap={minorScale(3)}
          onClick={() => setCurrentAuthView(AUTH_VIEW.SIGNIN.value)}
        >
          Sign in
          <ArrowRightIcon />
        </Btn>
      </Pane>
    </>
  );
};
