import React from 'react';
import {
  ListItem,
  Pane,
  SideSheet,
  UnorderedList,
  minorScale,
  Avatar,
  majorScale,
} from 'evergreen-ui';

import { NavLink, Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { authUserVar, isAuthVar } from '../../../helpers/auth';
import { PRIMARY_NAV_ITEMS } from '../desktop-header/desktop-header';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/menu-hamburger.svg';
import AuthHeader from '../../auth-header/auth-header';
import { SuperAffiliateContext } from '../../../providers/super-affiliate';
import { WithSubdomainLink } from '../../../hooks/use-subdomain';
import Skeleton from 'react-loading-skeleton';
import Notification from '../../notification-bell/notification-bell';
import { ReactComponent as CokobarLogo } from '../../../assets/icons/cokobarLogo.svg';
import CokobarLogoMobile from '../../../assets/icons/cokobar-mobile-Logo-Icon.png';

const MobileHeader = ({ loadingLogo, subdomainLogoUrl }) => {
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);
  const authUserFullName = `${authUser?.firstName} ${authUser?.lastName}`;

  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const [isMobileModalOpen, setIsMobileModalOpen] = React.useState(false);

  return (
    <Pane
      is="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <NavLink to="/">
        {loadingLogo ? (
          <Skeleton width={50} />
        ) : (
          <img
            src={subdomainLogoUrl || superAffiliate.logo}
            alt="Logo"
            style={{ verticalAlign: 'middle', maxWidth: '50px' }}
          />
        )}
      </NavLink>

      <SideSheet
        width="80%"
        isShown={isMobileModalOpen}
        onCloseComplete={() => setIsMobileModalOpen(false)}
        preventBodyScrolling
        shouldCloseOnOverlayClick={true}
        containerProps={{
          marginLeft: 'auto',
          padding: '1rem',
          paddingBottom: '8rem',
          zIndex: 99999999,
        }}
      >
        <Pane display="flex" flexDirection="column" height="100%">
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={minorScale(10)}
          >
            <NavLink to="/">
              {loadingLogo ? (
                <Skeleton width={50} />
              ) : (
                //   <img
                //   src={subdomainLogoUrl || superAffiliate.logo}
                //   alt="Logo"
                //   width="120rem"
                // />

                <CokobarLogo />
              )}
            </NavLink>
          </Pane>

          <UnorderedList display="flex" flexDirection="column">
            {PRIMARY_NAV_ITEMS.filter((item) => item.shown).map(
              (navItem, i) => {
                const {
                  title,
                  exact,
                  path,
                  domainOnly,
                  unauthenticatedRedirect,
                } = navItem;

                const targetPath =
                  !authUser?.id && unauthenticatedRedirect
                    ? unauthenticatedRedirect
                    : path;

                const LinkComponent = domainOnly ? WithSubdomainLink : NavLink;

                return (
                  <ListItem key={i} fontSize="inherit">
                    <LinkComponent
                      isNavLink={!!domainOnly}
                      exact={exact}
                      to={targetPath}
                      activeStyle={{ borderBottom: '1px solid #fff' }}
                      style={{
                        display: 'inline-block',
                        color: 'var(--text-default-color)',
                      }}
                    >
                      {title}
                    </LinkComponent>
                  </ListItem>
                );
              }
            )}
          </UnorderedList>

          <Pane
            marginTop="100px"
            borderTop="1px solid var(--gray-lightest-color)"
            paddingY={minorScale(5)}
          >
            <AuthHeader />
          </Pane>
        </Pane>
      </SideSheet>
      <Pane display="flex" alignItems="center">
        <Pane marginRight={majorScale(3)}>
          <Notification />{' '}
        </Pane>

        {isAuth && (
          <Link to="/account/profile" exact="true">
            <Pane
              display="flex"
              alignItems="center"
              color="#fff"
              cursor="pointer"
              marginRight={majorScale(1)}
            >
              <Avatar
                size={minorScale(6)}
                appearance="solid"
                color="blue"
                name={authUserFullName}
                fontSize="1em"
                className="mr-sm-2"
              />
            </Pane>
          </Link>
        )}

        <HamburgerIcon
          cursor="pointer"
          onClick={() => setIsMobileModalOpen(true)}
        />
      </Pane>
    </Pane>
  );
};

export default MobileHeader;
