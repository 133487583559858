import React from 'react';
import {
  Dialog,
  Pane,
  Heading,
  majorScale,
  Paragraph,
  minorScale,
} from 'evergreen-ui';
import styled from '@emotion/styled';
import { useReactiveVar } from '@apollo/client';
import Btn from '../btn/btn';
import { authUserVar } from '../../helpers/auth';
import { useAppContext } from '../../providers/cookie-policy';
import FormCheckbox from '../form-checkbox/form-checkbox';
import { LOGIN_ATTEMPTS } from '../../helpers/enums';

const DialogStyle = styled(Dialog)`
  margin: 0;
`;

const GuestCheckoutDialog = () => {
  const { handleGuestCheckout, isGuestLogin } = useAppContext();
  const authUser = useReactiveVar(authUserVar);
  const [checked, setChecked] = React.useState(false);

  return (
    <DialogStyle
      isShown={true}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      hasFooter={false}
      hasHeader={false}
      // preventBodyScrolling
      width="700px"
    >
      <Pane padding={majorScale(2)}>
        {/* <Pane display="flex" justifyContent="flex-end">
            <Paragraph cursor="pointer" onClick={() => handleGuestCheckout()}>
              X
            </Paragraph>
          </Pane> */}

        <Pane marginBottom={majorScale(2)} textAlign="center">
          {isGuestLogin.loginFlow &&
            authUser.status === 'AWAITINGACTIVATION' && (
              <Heading fontSize="1.5em">
                {' '}
                {`Welcome back ${authUser.firstName}!`}{' '}
              </Heading>
            )}
          {!isGuestLogin.loginFlow && (
            <Heading fontSize="1.5em">Welcome to Cokobar!</Heading>
          )}
        </Pane>

        {isGuestLogin.loginFlow && authUser.status === 'AWAITINGACTIVATION' && (
          <Pane textAlign="center">
            <Paragraph fontSize="1em">
              You can continue and activate your account later.{' '}
            </Paragraph>
            <Paragraph fontSize="1em">
              Eager to buy your tickets immediately without activating your
              account? You certainly can! but to access your tickets from your
              personal profile area, you must activate your account.{' '}
            </Paragraph>

            <Paragraph fontSize="1em">
              {`You have not yet activated your account. No worries, we've just sent you a fresh activation link to your email (check for ${authUser.email}). Click it to unlock your personal profile area – that's where all your tickets live! The link expires in 24 hours, so don't miss out.`}{' '}
            </Paragraph>
          </Pane>
        )}

        {isGuestLogin.signupFlow &&
          authUser.status === 'AWAITINGACTIVATION' && (
            <Pane textAlign="center">
              <Paragraph fontSize="1em">
                You can continue and activate your account later.{' '}
              </Paragraph>
              <Paragraph fontSize="1em">
                Eager to buy your tickets immediately without activating your
                account? You certainly can! but to access your tickets from your
                personal profile area, you must activate your account.{' '}
              </Paragraph>

              <Paragraph fontSize="1em">{`You are just one step away. Please activate your account by clicking on the activation link we have sent to your email id ${authUser.email}. The link is valid only for 24 hours.`}</Paragraph>
            </Pane>
          )}

        {isGuestLogin.isEmailExists && (
          <Pane marginBottom={majorScale(2)} textAlign="center">
            <Paragraph fontSize="1em">
              This email address is already registered. Please sign in.
            </Paragraph>
          </Pane>
        )}

        <Pane display="flex" justifyContent="center">
          <FormCheckbox
            marginBottom={majorScale(3)}
            color="var(--primary-default-color)"
            size={minorScale(5)}
            label={
              isGuestLogin.isEmailExists
                ? 'Yes, I have understood.'
                : 'Yes, I have understood and will activate my account later.'
            }
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        </Pane>

        <Pane marginBottom={majorScale(2)}>
          <Pane display="flex" justifyContent="center">
            <Btn
              type="button"
              fontSize="1em"
              paddingX={majorScale(3)}
              columnGap={majorScale(1)}
              marginRight={majorScale(3)}
              onClick={() => handleGuestCheckout()}
              disabled={!checked}
            >
              {isGuestLogin.isBuyingFlow ? 'Proceed to buy tickets' : 'OK'}
            </Btn>
          </Pane>
        </Pane>
      </Pane>
    </DialogStyle>
  );
};

export default GuestCheckoutDialog;
