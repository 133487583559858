import axios from 'axios';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ImageUploading from 'react-images-uploading';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import {
  Pane,
  Dialog,
  Avatar,
  Heading,
  Paragraph,
  toaster,
  majorScale,
} from 'evergreen-ui';

import {
  getErrorMessage,
  getHttpErrorMessage,
} from '../../../helpers/functions';
import Btn from '../../../components/btn/btn';
import { authUserVar, setAuthUser, tokenVar } from '../../../helpers/auth';
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera.svg';
import { VIEWPORT_BREAKPOINTS } from '../../../helpers/enums';

const IDLE = 'IDLE';
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const SUCCESS = 'SUCCESS';

const UPDATE_USER_PROFILE_IMAGE_MUTATION = gql`
  mutation UpdateUserProfileImageMutation(
    $_set: user_set_input!
    $pk_columns: user_pk_columns_input!
  ) {
    update_user_by_pk(_set: $_set, pk_columns: $pk_columns) {
      profileImageUrl
    }
  }
`;

const btnList = [
  { title: 'Edit', value: 'profile' },
  { title: 'Change Password', value: 'change_password' },
];

function ProfileImageContainer({
  isButtonDisabled,
  onButtonClick,
  isFormOpen,
  formValues,
}) {
  const isMediumScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const [images, setImages] = React.useState([]);
  const token = tokenVar();
  const authUser = useReactiveVar(authUserVar);

  const authUserFullName = `${
    authUser?.FirstName ? authUser?.FirstName : authUser?.firstName
  } ${authUser?.LastName ? authUser?.LastName : authUser?.lastName}`;

  function onChange(imageList, addUpdateIndex) {
    setImages(imageList);
  }

  const [status, setStatus] = React.useState(IDLE);
  const [mutate] = useMutation(UPDATE_USER_PROFILE_IMAGE_MUTATION);
  const [tempProfileImageUrl, setTempProfileImageUrl] = React.useState();

  // async function updateProfileImage(imageUrl) {
  //   try {
  //     const { data } = await mutate({
  //       variables: {
  //         _set: { profileImageUrl: imageUrl },
  //         pk_columns: { id: authUser.id },
  //       },
  //       context: { headers: { authorization: `Bearer ${token}` } },
  //     });

  //     if (data) {
  //       setStatus(SUCCESS);

  //       const { profileImageUrl } = data.update_user_by_pk;

  //       setAuthUser({ ...authUser, profileImageUrl });
  //       setTempProfileImageUrl();

  //       toaster.success('Profile image updated successfully', {
  //         id: 'profile-img-success',
  //       });
  //     }
  //   } catch (error) {
  //     setStatus(ERROR);
  //     setAuthUser({ ...authUser, profileImageUrl: authUser.profileImageUrl });
  //     setTempProfileImageUrl();

  //     toaster.danger(getErrorMessage(error), { id: 'profile-img-error' });
  //   }
  // }

  // async function handleNewProfileImage(file) {
  //   try {
  //     setStatus(LOADING);

  //     const request = new FormData();
  //     request.append('image', file);

  //     const response = await axios.post(
  //       process.env.REACT_APP_IMAGE_UPLOAD_ENDPOINT,
  //       request
  //     );

  //     if (response.status === 200) {
  //       updateProfileImage(response.data.imageUrl);

  //       if (authUser.profileImageUrl) {
  //         deletePrevProfileImage();
  //       }
  //     }
  //   } catch (error) {
  //     setStatus(ERROR);
  //     setAuthUser({ ...authUser, profileImageUrl: authUser.profileImageUrl });
  //     setTempProfileImageUrl();

  //     getHttpErrorMessage(error);
  //   }
  // }

  async function deletePrevProfileImage() {
    try {
      const response = await axios.post(
        process.env.REACT_APP_IMAGE_DELETE_ENDPOINT,
        {
          input: { imageUrl: authUser.profileImageUrl },
        }
      );

      if (response.status === 200) {
        // no-op
      }
    } catch (error) {
      // no-op
      // throw new Error('Error occured while trying to save your image.');
    }
  }

  return (
    <>
      <Pane
        is="section"
        display="flex"
        flexDirection={isMediumScreen ? 'row' : 'column'}
        alignItems="center"
      >
        <Pane position="relative" marginRight={majorScale(4)}>
          {tempProfileImageUrl ? (
            <Pane
              width={120}
              height={120}
              borderRadius="100%"
              overflow="hidden"
              border="1px solid var(--host-gray-lighter-color)"
            >
              <img
                src={tempProfileImageUrl}
                alt={authUser.userDisplayedName}
                style={{ maxWidth: '100%' }}
              />
            </Pane>
          ) : authUser.profileImageUrl ? (
            <Pane
              width={120}
              height={120}
              borderRadius="100%"
              overflow="hidden"
              border="1px solid var(--host-gray-lighter-color)"
            >
              <img
                src={authUser.profileImageUrl}
                alt={authUser.userDisplayedName}
                style={{ maxWidth: '100%' }}
              />
            </Pane>
          ) : (
            <Avatar
              size={120}
              appearance="solid"
              color="blue"
              name={authUserFullName}
            />
          )}

          {/* <ImageUploading
            value={images}
            acceptType={['jpg', 'jpeg', 'png']}
            dataURLKey="data_url"
            onChange={onChange}
          >
            {({
              errors,
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
            }) => {
              return (
                <>
                  <Pane
                    width={majorScale(4)}
                    height={majorScale(4)}
                    position="absolute"
                    right={5}
                    bottom={5}
                  >
                    <CameraIcon cursor="pointer" onClick={onImageUpload} />
                  </Pane>

                  {imageList.length > 0 && (
                    <ProfileImageUploadDialog
                      isOpen={imageList.length > 0}
                      imageList={imageList}
                      status={status}
                      onImageUpdate={onImageUpdate}
                      onCloseComplete={() => setImages([])}
                      setTempProfileImageUrl={setTempProfileImageUrl}
                      handleOptimisticProfileImageUpdate={(image) => {
                        setImages([]);
                        setTempProfileImageUrl(image['data_url']);
                        handleNewProfileImage(image.file);
                      }}
                    />
                  )}
                </>
              );
            }}
          </ImageUploading> */}
        </Pane>

        <Pane
          color="#000"
          fontSize="1rem"
          textAlign={isMediumScreen ? 'unset' : 'center'}
        >
          <Heading color="inherit" fontSize="inherit">
            {authUserFullName}
          </Heading>

          <Paragraph marginY={majorScale(1)} color="inherit" fontSize="inherit">
            {authUser.email}
          </Paragraph>
          <Pane display="flex" columnGap={majorScale(1)}>
            {btnList.map(({ title, value }) => {
              return (
                <Btn
                  marginX={isMediumScreen ? 'unset' : 'auto'}
                  type="button"
                  look="primary-outlined"
                  fontSize="1em"
                  disabled={isButtonDisabled}
                  onClick={() =>
                    onButtonClick(value === isFormOpen ? '' : value)
                  }
                >
                  {title}
                </Btn>
              );
            })}
          </Pane>
        </Pane>

        <Pane
          marginLeft={isMediumScreen ? 'auto' : 'unset'}
          marginBottom={isMediumScreen ? 'auto' : 'unset'}
          marginTop={isMediumScreen ? 'unset' : '20px'}
        >
          <Link to="/host/create-event">
            <Btn type="button" look="tertiary-outlined" fontSize="1em">
              Create your events
            </Btn>
          </Link>
        </Pane>
      </Pane>
    </>
  );
}

function ProfileImageUploadDialog({
  isOpen,
  imageList,
  status,
  onImageUpdate,
  onCloseComplete,
  handleOptimisticProfileImageUpdate,
}) {
  return (
    <Dialog
      width={480}
      title="Edit Profile Image"
      isShown={isOpen}
      hasFooter={false}
      hasCancel={false}
      preventBodyScrolling
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
      onCloseComplete={onCloseComplete}
    >
      {({ close }) =>
        imageList.map((image, i) => {
          return (
            <Pane key={i}>
              <Pane
                width={360}
                maxHeight={420}
                overflowY="hidden"
                marginX="auto"
              >
                <img
                  src={image['data_url']}
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
              </Pane>

              <Pane
                paddingY={majorScale(2)}
                marginTop={majorScale(4)}
                display="flex"
                alignItems="center"
                justifyContent="center"
                columnGap={majorScale(2)}
                fontSize="1em"
                borderTop="1px solid rgba(0, 0, 0, 0.2)"
              >
                <Btn
                  fontSize="inherit"
                  isLoading={status === LOADING}
                  onClick={() => handleOptimisticProfileImageUpdate(image)}
                >
                  Save
                </Btn>

                <Btn
                  look="secondary-filled"
                  fontSize="inherit"
                  disabled={status === LOADING}
                  onClick={() => onImageUpdate(i)}
                >
                  Change
                </Btn>

                <Btn
                  look="tertiary-filled"
                  fontSize="inherit"
                  disabled={status === LOADING}
                  onClick={close}
                >
                  Cancel
                </Btn>
              </Pane>
            </Pane>
          );
        })
      }
    </Dialog>
  );
}

export default ProfileImageContainer;
