import React from 'react';
import {
  Dialog,
  Pane,
  majorScale,
  Heading,
  Paragraph,
  Button,
  toaster,
} from 'evergreen-ui';
import { Formik } from 'formik';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import * as yup from 'yup';
import styled from '@emotion/styled';
import { useMutation as useReactMutation } from 'react-query';

import {
  setIsAuth,
  isAuthVar,
  authUserVar,
  setAuthUser,
} from '../../helpers/auth';
import {
  getErrorMessage,
  parseJwt,
  cleanSpaces,
} from '../../helpers/functions';
import { NOTIFICATION_TYPE } from '../../helpers/enums';
import AppleAuth from '../../features/auth/apple-auth/apple-auth';
import GoogleAuth from '../../features/auth/google-auth/google-auth';
import FacebookAuth from '../../features/auth/facebook-auth/facebook-auth';
import FormInput from '../form-input/form-input';
import FormPasswordInput from '../form-password-input/form-password-input';
import Container from '../container/container';
import Btn from '../btn/btn';
import useResponsiveness from '../../hooks/use-responsiveness';
import apiManager from '../../helpers/apiManager';
import ResendActivationEmail from './resend-activation-email/resend-activation-email';
import ForgetPassword from './forget-password/forget-password';
import { SuperAffiliateContext } from '../../providers/super-affiliate';
import { useAppContext } from '../../providers/cookie-policy';

const CloseButton = styled(Button)`
  position: absolute;
  right: -3px;
  // border-radius: 50%;
  // padding: 25px 18px;
  top: 5px;
  font-size: 1.5em;
  opacity: 0.7;
  border: none;
  background: none;

  &:hover {
    border: none !important;
    background: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
`;
const SIGNIN_MUTATION = gql`
  mutation SigninMutation(
    $email: String!
    $password: String!
    $SuperCompanyId: String!
  ) {
    login(email: $email, password: $password, SuperCompanyId: $SuperCompanyId) {
      id
      access_token
    }
  }
`;

export const SIGNIN_FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  password: yup.string().required('Password is required'),
});

const SignInModel = ({
  isSignin,
  setISignin,
  setIsShown,
  isPromoters,
  CompanyId,
  isFollowHostIncluded,
  refetch,
  isFollow,
}) => {
  const { setGuestLogin } = useAppContext();
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);
  const [isActive, setActive] = React.useState(false);
  const [isForgetPassword, setForgetPassword] = React.useState(false);
  const [Loader, setLoader] = React.useState(false);
  const { superAffiliate } = React.useContext(SuperAffiliateContext);

  // const [mutate] = useMutation(SIGNIN_MUTATION);
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutateAsync: followedHost } = useReactMutation(
    apiManager.FollowTheHostAsync
  );

  const followhost = async (id) => {
    try {
      setLoader(true);

      const { data, message } = await followedHost({
        followedByUserId: id,
        hostId: CompanyId,
        notificationTypeId: NOTIFICATION_TYPE?.EVENTTICKETSALE,
        isFollowing: !isFollowHostIncluded,
      });

      if (data) {
        toaster.success(message || 'Thanks for follow host.');
        setLoader(false);
        refetch();
      }
    } catch (error) {
      setLoader(false);
      toaster.danger(error);
    }
  };

  async function handleSignin(values) {
    try {
      setIsLoading(true);
      // const { data } = await mutate({
      //   variables: {
      //     ...values,
      //     SuperCompanyId: superAffiliate.superAffiliateUserId,
      //   },
      // });
      const { data: apiData } = await apiManager.VerifyUser({
        ...values,
        superCompanyId: superAffiliate.superAffiliateUserId,
        isAppuser: false,
      });

      if (apiData) {
        const accessToken = apiData.jWT;
        apiManager.setToken(accessToken);

        // const unwantedField = 'https://hasura.io/jwt/claims';
        const {
          iat,
          // [unwantedField]: unwanted,
          loginAttempts,
          ...user
        } = parseJwt(accessToken);

        // setGuestLogin(loginAttempts);
        setIsAuth(accessToken);
        setAuthUser(user);

        toaster.success('Signin successful.', { id: 'signin-success' });

        if (!authUser.hostId && isAuth) {
          return setIsShown(true);
        } else if (isAuth && authUser.hostId) {
          return setIsShown(true);
        } else if (!isPromoters) {
          return followhost(user.id);
        } else {
          return setIsShown(true);
        }
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  }

  // const socialmediaSignin = () => {
  //   if (isPromoters) {
  //     setIsShown(true);
  //   } else {
  //     return null;
  //   }
  // };

  const { isLaptop } = useResponsiveness();

  return (
    <Pane>
      <Dialog
        isShown={isSignin}
        onCloseComplete={() => setISignin(false)}
        confirmLabel="Custom Label"
        hasFooter={false}
        contentContainerProps={{
          paddingY: '32px',
        }}
        preventBodyScrolling={true}
        hasHeader={false}
        width="784px"
        shouldCloseOnOverlayClick={false}
      >
        <Pane position="relative">
          <Heading textAlign="center" fontSize="1.5em">
            Sign in
          </Heading>
          <>
            <Pane
              marginY={majorScale(2)}
              className="row w-100"
              justifyContent="space-between"
            >
              <Pane className="col-12 col-md-6">
                <GoogleAuth
                  followhost={followhost}
                  isFollow={isFollow}
                  isPromoters={isPromoters}
                  setIsShown={setIsShown}
                />
              </Pane>
              {/* hide facebook */}
              <Pane className="col-12 col-md-4" display="none">
                <FacebookAuth />
              </Pane>
              <Pane className="col-12 col-md-6">
                <AppleAuth
                  followhost={followhost}
                  isFollow={isFollow}
                  isPromoters={isPromoters}
                  setIsShown={setIsShown}
                  isFollowHostIncluded={isFollowHostIncluded}
                  CompanyId={CompanyId}
                  refetch={refetch}
                />
              </Pane>
            </Pane>

            <Formik
              validationSchema={SIGNIN_FORM_SCHEMA}
              initialValues={{ email: '', password: '' }}
              onSubmit={(values) => handleSignin(cleanSpaces(values))}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      marginBottom={majorScale(2)}
                      inputHeight={majorScale(5)}
                      label="Email"
                      inputMode="email"
                      name="email"
                      placeholder="Enter email"
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                      validationMessage={touched.email && errors.email}
                      onChange={handleChange}
                    />

                    <FormPasswordInput
                      marginBottom={majorScale(2)}
                      inputHeight={majorScale(5)}
                      label="Password"
                      name="password"
                      placeholder="Enter password"
                      value={values.password}
                      isInvalid={touched.password && !!errors.password}
                      validationMessage={touched.password && errors.password}
                      onChange={handleChange}
                    />

                    <Pane display="flex" justifyContent="space-between">
                      <Paragraph marginBottom={majorScale(2)} textAlign="right">
                        <Btn
                          type="button"
                          fontSize="1em"
                          look="primary-minimal"
                          paddingX={0}
                          marginLeft="auto"
                          onClick={() => {
                            setISignin(false);
                            setActive(true);
                          }}
                        >
                          Resend Activation Email
                        </Btn>
                      </Paragraph>

                      <Paragraph marginBottom={majorScale(2)} textAlign="right">
                        <Btn
                          type="button"
                          fontSize="1em"
                          look="primary-minimal"
                          paddingX={0}
                          marginLeft="auto"
                          onClick={() => {
                            setForgetPassword(true);
                            setISignin(false);
                          }}
                        >
                          Reset Password
                        </Btn>
                      </Paragraph>
                    </Pane>

                    <Container maxWidth={isLaptop ? 300 : 'unset'}>
                      <Btn
                        type="submit"
                        width="100%"
                        fontSize="1em"
                        isLoading={isLoading}
                      >
                        Sign in
                      </Btn>
                    </Container>
                  </form>
                );
              }}
            </Formik>
          </>
        </Pane>
        <CloseButton onClick={() => setISignin(false)}>X</CloseButton>
      </Dialog>
      <ResendActivationEmail
        isActive={isActive}
        setActive={setActive}
        setISignin={setISignin}
      />
      <ForgetPassword
        setForgetPassword={setForgetPassword}
        isForgetPassword={isForgetPassword}
        setISignin={setISignin}
      />
    </Pane>
  );
};

export default SignInModel;
