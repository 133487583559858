import styled from '@emotion/styled';
import { Checkbox } from 'evergreen-ui';

const FormCheckbox = styled(Checkbox)`
  align-items: center;

  & > div {
    border: ${(props) =>
      `2px solid ${props.color || 'var(--secondary-default-color)'}`};
    width: ${(props) => `${props.size || 15}px`};
    height: ${(props) => `${props.size || 15}px`};
  }

  & > input:focus + div,
  & > input:checked + div {
    box-shadow: none !important;
  }

  & > input:checked + div {
    background-image: none !important;
    background-color: ${(props) =>
      `${props.color || 'var(--secondary-default-color)'} !important`};
  }

  & > input:disabled + div {
    background-image: none !important;
    background-color: #f4f5f9 !important;
    border-color: gray !important;
  }

  & > span {
    line-height: 1.2;
    color: var(--text-default-color);
    font-size: ${(props) => `${props.fontSize || 15}px`};
  }
`;

export default FormCheckbox;
