import styled from '@emotion/styled';
import { TextInputField } from 'evergreen-ui';

const FormInput = styled(TextInputField)`
  box-shadow: none;
  border-radius: 5px;
  border-top-left-radius: ${(props) => props.borderTopLeftRadius || '5px'};
  border-top-right-radius: ${(props) => props.borderTopRightRadius || '5px'};
  border-bottom-right-radius: ${(props) =>
    props.borderBottomRightRadius || '5px'};
  border-bottom-left-radius: ${(props) =>
    props.borderBottomLeftRadius || '5px'};
  border: 1px solid var(--gray-lightest-color);
  color: #000;
  background-color: #fff;
  font-size: 0.875rem;
  transition: 0.5s ease all;

  &::placeholder {
    color: var(--black-lighter-color);
  }

  &:focus {
    box-shadow: none !important;
    border-color: var(--secondary-dark-color);
  }
`;

export default FormInput;
