import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Pane,
  Label,
  Paragraph,
  ErrorIcon,
  minorScale,
  majorScale,
} from 'evergreen-ui';

const customStyles = {
  // container: (provided, state) => {
  //   return {
  //     ...provided,
  //     height: '100%',
  //   };
  // },

  control: (provided, state) => {
    return {
      ...provided,
      minHeight: 'unset',
      width: state.selectProps.width || '100%',
      height: state.selectProps.height || 40,
      color: state.selectProps.color || '#000',
      fontSize: state.selectProps.fontSize || '0.875rem',
      borderRadius: state.selectProps.borderRadius || 5,
      backgroundColor: state.selectProps.isDisabled
        ? '#F4F5F9'
        : state.selectProps.backgroundColor || '#fff',
      borderColor: state.selectProps.isInvalid
        ? '#D14343 !important'
        : state.menuIsOpen || state.isFocused
        ? 'var(--secondary-dark-color) !important'
        : 'var(--gray-lightest-color) !important',
      boxShadow: 'none !important',
      transition: '0.5s ease all',
    };
  },

  valueContainer: (provided) => {
    return {
      ...provided,
      height: '100%',
    };
  },

  placeholder: (provided) => {
    return {
      ...provided,
      color: 'var(--black-lighter-color)',
    };
  },

  singleValue: (provided) => {
    return {
      ...provided,
      color: 'inherit',
    };
  },

  indicatorsContainer: (provided) => {
    return {
      ...provided,
      height: '100%',
    };
  },

  indicatorSeparator: (provided) => {
    return {
      ...provided,
      display: 'none',
    };
  },

  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: 'var(--black-lighter-color)',
    };
  },

  menu: (provided) => {
    return {
      ...provided,
      backgroundColor: '#FEFEFE',
      color: 'var(--black-light-color)',
      fontSize: '0.875rem',
    };
  },

  menuList: (provided) => {
    return {
      ...provided,
      maxHeight: 180,
    };
  },

  option: (provided) => {
    return {
      ...provided,
      paddingTop: 5,
      paddingBottom: 5,
    };
  },

  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: 'var(--blue-light-color)',
      borderRadius: '1rem',
      padding: '4px',
      color: 'var(--text-default-color)',
    };
  },

  multiValueRemove: (provided) => {
    return {
      ...provided,
      backgroundColor: 'var(--text-default-color)',
      color: '#fff',
      borderRadius: '50%',
      cursor: 'pointer',
    };
  },
};

function getValueWithLabel(value, options, isMulti) {
  if (isMulti) {
    return options.filter((x) => value.includes(x.value));
  }
  if (value && value.hasOwnProperty('label')) {
    return value;
  }
  return options.find((x) => x.value === value);
}

const StyledSelect = styled(Select)`
  .form-select-box__dropdown-indicator svg {
    width: 16px;
  }
`;

function FormSelectBox({
  label,
  name,
  value,
  options = [],
  placeholder,
  isLoading,
  isInvalid,
  validationMessage,
  onChange,
  isMulti = false,
  ...rest
}) {
  return (
    <Pane fontFamily="Proxima Nova, sans-serif">
      {label && (
        <Pane marginBottom={majorScale(1)} display="flex">
          <Label>{label}</Label>
        </Pane>
      )}
      <StyledSelect
        styles={customStyles}
        name={name}
        isMulti={isMulti}
        value={value && getValueWithLabel(value, options, isMulti)}
        options={options}
        isLoading={isLoading}
        classNamePrefix="form-select-box"
        placeholder={placeholder}
        isInvalid={isInvalid}
        onChange={onChange}
        {...rest}
      />
      {isInvalid && validationMessage && (
        <Pane
          marginTop={majorScale(1)}
          display="flex"
          columnGap={minorScale(2)}
          color="#D14343"
        >
          <ErrorIcon size={14} />
          <Paragraph
            letterSpacing={0}
            color="#D14343"
            lineHeight="18px"
            fontSize="13px"
            fontWeight={400}
            marginBottom={0}
          >
            {validationMessage}
          </Paragraph>
        </Pane>
      )}
    </Pane>
  );
}

FormSelectBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isInvalid: PropTypes.bool,
  validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default FormSelectBox;
