import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Link as AnchorLink } from 'evergreen-ui';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import {
  Pane,
  Text,
  ListItem,
  TextInput,
  UnorderedList,
  toaster,
  minorScale,
  majorScale,
} from 'evergreen-ui';

import Btn from '../btn/btn';
import Container from '../container/container';
import { TS_ACCEPT_COOKIES } from '../../constants';
import clientStorage from '../../libs/client-storage';
import { VIEWPORT_BREAKPOINTS } from '../../helpers/enums';
import { getErrorMessage, textToBoolean } from '../../helpers/functions';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebooks.svg';

import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import { ReactComponent as AppleStoreIcon } from '../../assets/icons/app-store.svg';
import { ReactComponent as GooglePlaystoreIcon } from '../../assets/icons/google-play.svg';
import { SuperAffiliateContext } from '../../providers/super-affiliate';
import { WithSubdomainLink } from '../../hooks/use-subdomain';
import { useAppContext } from '../../providers/cookie-policy';

const NEWSLETTER_SUBSCRIPTION_MUTATION = gql`
  mutation NewsletterSubscriptionMutation($email: String!) {
    insert_subscribers_one(
      on_conflict: { constraint: subscribers_email_key, update_columns: email }
      object: { email: $email }
    ) {
      email
    }
  }
`;

const FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
});

const SECONDARY_NAV_ITEMS = [
  {
    shown: true,
    title: 'About us',
    path: '/about-us',
  },
  {
    shown: textToBoolean(process.env.REACT_APP_SHOW_HOST_EVENT_LINK),
    title: 'Create your events',
    path: '/host/create-event',
    domainOnly: true,
  },
  {
    shown: true,
    title: 'How it works',
    path: '/how-it-works',
  },

  {
    shown: true,
    title: 'FAQs',
    path: '/faqs',
  },
  {
    shown: true,
    title: 'Contact us',
    path: '/contact-us',
  },
  {
    shown: true,
    title: 'Privacy',
    path: '/privacy-policy',
  },
  {
    shown: true,
    title: 'Help',
    path: '/help-center',
  },
  {
    shown: true,
    title: 'Terms and Conditions',
    path: '/term-and-condition',
  },
];

const StyledListContainer = styled(UnorderedList)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    display: flex;
  }
`;

const DownloadsAndSubscribe = styled(Pane)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.sm}px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    padding: 1rem 0 2rem 0;

    .download-options {
      margin: unset;
    }
  }
`;

const CopyrightAndSocials = styled(Pane)`
  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    flex-direction: row-reverse;
    border-top: 1px solid #fff;

    .icons {
      width: unset;
      // gap: 2rem;
      border-bottom: unset;
      margin-top: unset;
    }

    .copyright {
      font-size: 0.95em;
    }
  }
`;

const StyledList = styled(ListItem)`
  &:hover {
    color: var(--secondary-default-color);
  }

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    &:nth-of-type(2) {
      order: 6;
      margin-left: auto !important;
    }
  }
`;

const StyledExternalLink = styled(AnchorLink)`
  color: var(--primary-default-color);

  &:hover {
    color: var(--primary-default-color);
    opacity: 0.5;
  }
`;

const SubscriptionForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    width: 360px;

    input {
      /* width: 300px; */
    }

    button {
      width: 40px;
    }
  }

  @media screen and (min-width: ${VIEWPORT_BREAKPOINTS.md}px) {
    margin-left: auto !important;
  }

  input {
    box-shadow: none;
    font-size: 0.875em;
    color: #000;
    border: 1px solid var(--gray-lightest-color);
    /* border-right: none; */
    transition: 0.5s ease all;
    width: 70%;

    &::placeholder {
      color: var(--black-lighter-color);
    }

    &:focus {
      box-shadow: none !important;
      border-color: var(--secondary-dark-color);
    }
  }

  button {
    height: 40px;
    width: 30%;
  }
`;

export const AppLink = styled(AnchorLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    box-shadow: none;
  }
`;

const Footer = ({ waitlistEnabled }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [mutate] = useMutation(NEWSLETTER_SUBSCRIPTION_MUTATION);
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const { isCookiesAccepted } = useAppContext();

  const handleNewsletterSubscription = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      const { data } = await mutate({
        variables: { ...values },
      });

      if (data) {
        toaster.success('Newsletter subscription successful');
        resetForm();
      }
    } catch (error) {
      getErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isLaptop = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const hasNotAcceptedCookies = clientStorage.getItem(TS_ACCEPT_COOKIES)
    ? false
    : true;

  return (
    <Pane
      backgroundColor="var(--secondary-dark-color)"
      marginTop={majorScale(8)}
      className="footerSpacing"
    >
      <Container
        maxWidth="100%"
        paddingTop={hasNotAcceptedCookies ? majorScale(2) : majorScale(9)}
        fontSize="1rem"
        paddingBottom={
          isLaptop && !isCookiesAccepted
            ? majorScale(12)
            : isLaptop && waitlistEnabled
            ? majorScale(10)
            : isLaptop
            ? majorScale(5)
            : !isCookiesAccepted
            ? majorScale(18)
            : waitlistEnabled
            ? majorScale(14)
            : majorScale(1)
        }
      >
        <StyledListContainer display="grid" gridTemplateColumns="1fr 1fr">
          {SECONDARY_NAV_ITEMS.filter((navItem) => navItem.shown).map(
            (navItem, i) => {
              const key = i;
              if (navItem.domainOnly) {
                return (
                  <StyledList
                    key={key}
                    color="var(--text-default-color)"
                    cursor="pointer"
                  >
                    <WithSubdomainLink
                      isNavLink
                      exact={navItem.exact}
                      to={navItem.path}
                      activeStyle={{ color: 'var(--secondary-default-color)' }}
                      style={{
                        display: 'inline-block',
                        padding: '8px 16px',
                        color: 'inherit',
                        fontSize: '.9rem',
                      }}
                    >
                      {navItem.title}
                    </WithSubdomainLink>
                  </StyledList>
                );
              }
              return (
                <StyledList
                  key={key}
                  color="var(--text-default-color)"
                  cursor="pointer"
                >
                  <NavLink
                    exact
                    to={navItem.path}
                    // activestyle={{ color: 'var(--secondary-default-color)' }}
                    style={{
                      display: 'inline-block',
                      padding: '8px 16px',
                      color: '#fff',
                      fontSize: '.9rem',
                    }}
                  >
                    {navItem.title}
                  </NavLink>
                </StyledList>
              );
            }
          )}
        </StyledListContainer>
        <Pane>
          {/* <DownloadsAndSubscribe
            paddingY={majorScale(1)}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Pane
              className="download-options"
              display="flex"
              justifyContent="space-between"
              paddingY={majorScale(1)}
              marginY={majorScale(1)}
              paddingBottom={majorScale(2)}
              gap={'1.5rem'}
            >
              {textToBoolean(process.env.REACT_APP_SHOW_APP_DOWNLOAD_LINKS) && (
                <>
                  <AppLink
                    href={process.env.REACT_APP_APPLE_STORE_TICKETSIR_LINK}
                    target="_blank"
                    rel="noreferrer"
                    color="neutral"
                    aria-label="download app on apple store"
                  >
                    <AppleStoreIcon cursor="pointer" />
                  </AppLink>
                  <AppLink
                    href={process.env.REACT_APP_GOOGLE_PLAY_TICKETSIR_LINK}
                    target="_blank"
                    rel="noreferrer"
                    color="neutral"
                    aria-label="download app on play store"
                  >
                    <GooglePlaystoreIcon cursor="pointer" />
                  </AppLink>
                </>
              )}
            </Pane> */}

          {/* <Formik
              enableReinitialize
              validationSchema={FORM_SCHEMA}
              initialValues={{ email: '' }}
              onSubmit={handleNewsletterSubscription}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => {
                return (
                  // <SubscriptionForm onSubmit={handleSubmit}>
                  //   <TextInput
                  //     height={40}
                  //     borderTopRightRadius={0}
                  //     borderBottomRightRadius={0}
                  //     borderTopLeftRadius={minorScale(1)}
                  //     borderBottomLeftRadius={minorScale(1)}
                  //     name="email"
                  //     placeholder="Enter your email"
                  //     value={values.email}
                  //     isInvalid={touched.email && !!errors.email}
                  //     onChange={handleChange}
                  //     required
                  //   />

                  //   <Btn
                  //     type="submit"
                  //     borderTopLeftRadius={0}
                  //     borderBottomLeftRadius={0}
                  //     borderTopRightRadius={minorScale(1)}
                  //     borderBottomRightRadius={minorScale(1)}
                  //     color="#fff"
                  //     isLoading={isLoading}
                  //   >
                  //     Subscribe
                  //   </Btn>
                  // </SubscriptionForm>
                );
              }}
            </Formik> */}
          {/* </DownloadsAndSubscribe> */}
        </Pane>

        <CopyrightAndSocials
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingX={majorScale(1)}
          justifyContent="space-between"
        >
          <Pane
            display="flex"
            alignItems="center"
            paddingY={minorScale(4)}
            marginTop={minorScale(2)}
            justifyContent="space-between"
            width="100%"
            className="icons"
            borderBottom="1px solid var(--black-lightest-color)"
          >
            <StyledExternalLink
              href={process.env.REACT_APP_INSTAGRAM_LINK}
              target="_blank"
              rel="noreferrer"
              color="neutral"
              aria-label="follow us on instagram"
            >
              <InstagramIcon cursor="pointer" className="mr-sm-3" />
            </StyledExternalLink>
            <StyledExternalLink
              href={process.env.REACT_APP_TWITTER_LINK}
              target="_blank"
              rel="noreferrer"
              color="neutral"
              aria-label="follow us on twitter"
            >
              <TwitterIcon cursor="pointer" className="mx-sm-3" />
            </StyledExternalLink>
            <StyledExternalLink
              href={process.env.REACT_APP_FACEBOOK_LINK}
              target="_blank"
              rel="noreferrer"
              color="neutral"
              aria-label="follow us on Facebook"
            >
              <FacebookIcon cursor="pointer" className="mx-sm-3" />
            </StyledExternalLink>
            {/* <StyledExternalLink
              href="https://www.youtube.com/channel/UCB-C3lq0tsIFZ7g0d0CqJMA"
              target="_blank"
              rel="noreferrer"
              color="neutral"
              aria-label="subscribe on youtube"
            >
              <YoutubeIcon cursor="pointer" className="ml-sm-3" />
            </StyledExternalLink> */}
          </Pane>

          <Text
            is="small"
            color="var(--text-white-color)"
            textAlign="center"
            fontSize="1em"
            paddingY={minorScale(5)}
            className="copyright"
          >
            &copy; {new Date().getFullYear()} {superAffiliate.domainName} is a
            registered trademark of Whytelion Ltd. (Powered by TicketSir)
          </Text>
        </CopyrightAndSocials>
      </Container>
    </Pane>
  );
};

export default Footer;
