import {
  Heading,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  Text,
} from 'evergreen-ui';
import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Link as AnchorLink } from 'evergreen-ui';

import Header from '../header/header';
import Container from '../container/container';
import AuthBG from '../../assets/icons/auth-bg.svg';
import { textToBoolean } from '../../helpers/functions';
import { SuperAffiliateContext } from '../../providers/super-affiliate';
import { ReactComponent as AppleStoreIcon } from '../../assets/icons/app-store.svg';
import { ReactComponent as GooglePlaystoreIcon } from '../../assets/icons/google-play.svg';

export const AppLink = styled(AnchorLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    box-shadow: none;
  }
`;

const authMessages = {
  signin: {
    path: 'register',
    buttonText: 'Sign up',
    linkDescription: "Don't have an account yet?",
  },
  signup: {
    path: 'signin',
    buttonText: 'Sign in',
    linkDescription: 'Already have an account?',
  },
  register_success: {
    path: '',
    buttonText: '',
    linkDescription: '',
  },
};

const AuthLayout = ({ type, children, isPromoter, isSuccess }) => {
  const pathTo = authMessages[type]?.path || '';
  const buttonText = authMessages[type]?.buttonText || '';
  const linkDescription = authMessages[type]?.linkDescription || '';

  const { superAffiliate } = React.useContext(SuperAffiliateContext);

  return (
    <>
      <Pane
        width="100%"
        minHeight={isPromoter && isSuccess ? '250px' : '100vh'}
        display="flex"
        className="row h-100 mx-0"
        position="relative"
      >
        {superAffiliate.withoutHeader && !isPromoter && (
          <Pane
            backgroundColor="var(--secondary-dark-color)"
            position="fixed"
            top={0}
            width="100%"
            zIndex={1}
          >
            <Container maxWidth="100%" paddingY={majorScale(1)}>
              <Header />
            </Container>
          </Pane>
        )}
        {!isPromoter && (
          <Pane
            className="d-none d-md-flex col-md-6"
            alignItems="center"
            justifyContent="center"
            backgroundImage={`url("${AuthBG}")`}
            backgroundColor="var(--secondary-light-color)"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="cover"
            borderTopRightRadius={minorScale(1)}
            borderBottomRightRadius={minorScale(1)}
          >
            <Pane
              // TODO: unhide content {change display to flex}
              display="flex"
              flexDirection="column"
              // justifyContent="space-between"
              background=" linear-gradient(108.33deg, rgba(3, 51, 110, 0.25) 7.89%, rgba(38, 114, 171, 0.25) 91.62%)"
              border="1px solid rgba(255, 255, 255, 0.6)"
              backdrop-filter="blur(12px)"
              borderRadius="1rem"
              width="453px"
              maxWidth="80%"
              height="560px"
              color="#fff"
              paddingY={majorScale(4)}
              paddingLeft={majorScale(4)}
            >
              <Pane marginBottom={majorScale(4)}>
                <img width="250px" src={superAffiliate.logo} alt="Logo" />
                <Heading
                  color="inherit"
                  fontStyle="normal"
                  fontWeight="900"
                  fontSize="1.9rem"
                  marginTop={majorScale(4)}
                >
                  Experience <br /> awesome events <br /> with {' '}
                  {superAffiliate.businessName}
                </Heading>
              </Pane>

              <Pane>
                <Paragraph
                  color="inherit"
                  fontSize="1em"
                  marginY={majorScale(1)}
                >
                  {linkDescription}
                </Paragraph>
                <Link to={`/${pathTo}`}>
                  <Text color="#fff" fontSize="1.5em" fontWeight="900">
                    {buttonText}
                  </Text>
                </Link>
              </Pane>

              {/* <Pane
                className="download-options"
                display="flex"
                justifyContent="flex-start"
                paddingY={majorScale(1)}
                marginY={majorScale(1)}
                paddingBottom={majorScale(2)}
                gap={'1.5rem'}
              >
                {textToBoolean(
                  process.env.REACT_APP_SHOW_APP_DOWNLOAD_LINKS
                ) && (
                  <>
                    <AppLink
                      href={`${process.env.REACT_APP_APP_STORE_LINK}`}
                      target="_blank"
                      rel="noreferrer"
                      boxShadow="none"
                      border="none"
                      borderRadius="0"
                      height="fit-content"
                    >
                      <AppleStoreIcon cursor="pointer" />
                    </AppLink>
                    <AppLink
                      href={process.env.REACT_APP_GOOGLE_PLAY_LINK}
                      target="_blank"
                      rel="noreferrer"
                      color="neutral"
                    >
                      <GooglePlaystoreIcon cursor="pointer" />
                    </AppLink>
                  </>
                )}
              </Pane> */}
            </Pane>
          </Pane>
        )}
        <Pane
          className={isPromoter ? 'col-12' : 'col-12 col-md-6'}
          paddingTop={!isPromoter && majorScale(5)}
        >
          {children}
        </Pane>
      </Pane>
    </>
  );
};

export default AuthLayout;
