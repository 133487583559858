import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, majorScale, minorScale, Pane } from 'evergreen-ui';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Container from '../../components/container/container';
import notFoundImage from '../../assets/images/not-found.png';
import { SuperAffiliateContext } from '../../providers/super-affiliate';

function NotFound() {
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  return (
    <>
      {superAffiliate.withoutHeader && (
        <Pane backgroundColor="var(--secondary-dark-color)">
          <Container maxWidth="100%" paddingY={majorScale(1)}>
            <Header />
          </Container>
        </Pane>
      )}

      <Container
        maxWidth="100%"
        minHeight="55vh"
        paddingY={minorScale(6)}
        // display="flex"
        alignItems="center"
        columnGap={minorScale(6)}
      >
        <Heading
          fontWeight={700}
          marginBottom={majorScale(4)}
          textAlign="center"
          fontSize="1.3em"
        >
          We are sorry, but the page you are looking for is no longer available.
          It might have been deleted or expired. You can return to the
          <Link to="/" style={{ textDecoration: 'underline' }}>
            {' '}
            Home Page
          </Link>
          .
        </Heading>
        <Pane textAlign="center">
          <Pane
            is="img"
            maxHeight="70vh"
            src={notFoundImage}
            alt=""
            maxWidth="100%"
          ></Pane>
        </Pane>
      </Container>

      {superAffiliate.withoutHeader && <Footer />}
    </>
  );
}

export default NotFound;
