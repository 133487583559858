import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, NavLink } from 'react-router-dom';
import { setAuthUser, setIsAuth } from '../helpers/auth';
import { getHttpErrorMessage, parseJwt } from '../helpers/functions';
import { superAffiliateObject } from '../providers/super-affiliate';

const environment = process.env.REACT_APP_WEB_ENVIRONMENT; // enum['local', 'production', 'staging', 'development']
const restrictedSubdomain = process.env.REACT_APP_RESTRICTED_SUBDOMAIN;

const webDomain = process.env.REACT_APP_WEB_DOMAIN;

const hostWebDomain = process.env.REACT_APP_WEB_DOMAIN_HOST;
const hostEnvironment = process.env.REACT_APP_WEB_DOMAIN_HOST_ENVIRONMENT;

const HOST_LOGO_URL = gql`
  query HostLogoUrl($name: String!) {
    allCompanies(where: { companyName: { eq: $name } }) {
      items {
        logoImageUrl
      }
    }
  }
`;

const HOSTNAME_QUERY = gql`
  query HostName($name: String!) {
    Company: allCompanies(where: { companyName: { eq: $name } }) {
      Company: items {
        CompanyName: companyName
        CompanyId: companyId
      }
    }
  }
`;

// get webApp environment
const getEnvironment = () => {
  if (!['production', 'local'].includes(environment)) {
    return environment;
  }
  return '';
};

// subdomainUrl
export const subdomainUrl = (hostname) => {
  if (environment === 'local') {
    return `${hostname}.${webDomain}:${window.location.port}`;
  }
  if (getEnvironment()) {
    return `${hostname}-${environment}.${webDomain}`;
  }

  return `${hostname}.${webDomain}`;
};

// getSubdomainUrl
export const getSubdomainUrl = (hostname) => {
  if (environment === 'local') {
    return `${hostname}.${hostWebDomain}:${window.location.port}`;
  }
  if (!['production', 'local'].includes(hostEnvironment)) {
    return `${hostname}-${hostEnvironment}.${hostWebDomain}`;
  }

  return `${hostname}.${hostWebDomain}`;
};

// get subdomain from url
export const getSubdomainFromUrl = () => {
  // get url
  const url = window.location.hostname;

  // get subdomain out of url
  let _subdomain = url.split(webDomain)[0].slice(0, -1);
  if (getEnvironment()) {
    _subdomain = _subdomain.split(environment)[0].slice(0, -1);
  }

  // return empty string if subdomain is one of restricted subdomains
  const restrictedSubdomains = restrictedSubdomain.split(' ');

  if (restrictedSubdomains.includes(_subdomain)) {
    return '';
  }

  return _subdomain;
};

// handle domain link
export const handleDomainLink = () => {
  if (environment === 'local') {
    return 'localhost:3000';
  }

  if (getEnvironment()) {
    return `${getEnvironment()}.${webDomain}`;
  }

  return webDomain;
};

// useSubdomain hook
function useSubdomain() {
  const [subdomain, setSubdomain] = React.useState('');
  const [hostname, setHostname] = React.useState('');

  const { loading, data } = useQuery(HOSTNAME_QUERY, {
    variables: { name: hostname },
  });

  React.useEffect(() => {
    const _subdomain = getSubdomainFromUrl();

    setHostname(_subdomain);
  }, []);

  React.useLayoutEffect(() => {
    if (hostname) {
      // catch invalid subdomain and redirect to homepage
      if (!loading && !data?.Company?.Company?.length) {
        window.location.replace(`https://${handleDomainLink()}`);
      }

      setSubdomain(hostname);

      // if url contains token, set token and clear it from url
      if (window.location.href.match(/\?.+/)) {
        const paramsMatch = window.location.href.match(/\?.+/);

        const params = new URLSearchParams(paramsMatch[0]);
        const token = params.get('token');

        setIsAuth(token);

        const authUser = parseJwt(token);
        setAuthUser(authUser);

        window.history.replaceState(
          null,
          '',
          `https://${subdomainUrl(hostname)}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostname, loading]);

  return { hostname, loading, subdomain, setSubdomain };
}

// useSubdomain logo hook
export function useSubdomainLogo() {
  const { subdomain } = useSubdomain();

  return useQuery(HOST_LOGO_URL, {
    variables: { name: subdomain },
    onError: (error) => {
      getHttpErrorMessage(error);
    },
  });
}

// Component
export const WithSubdomainLink = ({ children, isNavLink, ...rest }) => {
  if (!!getSubdomainFromUrl()) {
    return (
      <a
        href={`https://${handleDomainLink()}${
          rest.to
        }?hostname=${getSubdomainFromUrl().replaceAll('"', '')}`}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if (isNavLink) {
    return (
      <NavLink cursor="pointer" {...rest}>
        {children}
      </NavLink>
    );
  }

  return (
    <Link to={rest.to} {...rest}>
      {children}
    </Link>
  );
};

export default useSubdomain;
