import * as yup from 'yup';
import * as React from 'react';
import { Field, Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { useMutation as ReactQueryMutation } from 'react-query';

import {
  Pane,
  Heading,
  toaster,
  minorScale,
  majorScale,
  UnorderedList,
  TickCircleIcon,
  ListItem,
} from 'evergreen-ui';

import {
  getErrorMessage,
  optionsToReactSelectFormat,
  capitalizeKeys,
} from '../../../helpers/functions';
import Btn from '../../../components/btn/btn';
import { VIEWPORT_BREAKPOINTS } from '../../../helpers/enums';
import Container from '../../../components/container/container';
import FormInput from '../../../components/form-input/form-input';
import { authUserVar, tokenVar, setAuthUser } from '../../../helpers/auth';
import ErrorStateMsg from '../../../components/error-state-msg/error-state-msg';
import FormSelectBox from '../../../components/form-select-box/form-select-box';
import LoadingStateSpinner from '../../../components/loading-state-spinner/loading-state-spinner';
import ProfileImageContainer from '../../../features/account/profile-image-container/profile-image-container';
import useCountries from '../../../hooks/use-countries';
import FormPasswordInput from '../../../components/form-password-input/form-password-input';
import apiManager from '../../../helpers/apiManager';
import ActivateYourAccountMessage from '../../../components/activate-your-account-message/activate-your-account-message';

const PROFILE_FORM_SCHEMA = yup.object().shape({
  FirstName: yup.string().when('isFormOpen', {
    is: (val) => val === 'profile',
    then: yup
      .string()
      .trim()
      .required('First name is required')
      .max(20, 'First name can be at most 20 characters'),
  }),
  LastName: yup.string().when('isFormOpen', {
    is: (val) => val === 'profile',
    then: yup
      .string()
      .trim()
      .required('Last name is required')
      .max(20, 'Last name can be at most 20 characters'),
  }),
  EmailAddress: yup.string().when('isFormOpen', {
    is: (val) => val === 'profile',
    then: yup
      .string()
      .trim()
      .required('Email is required')
      .email('Invalid email address'),
  }),
  AddressLine1: yup.string('Address Line 1 must be a string').trim().nullable(),
  AddressLine2: yup.string('Address Line 2 must be a string').trim().nullable(),
  PostCode: yup.string().trim().nullable(),
  City: yup.string('City must be a string').trim().nullable(),
  oldPassword: yup.string().when('isFormOpen', {
    is: (val) => val === 'change_password',
    then: yup.string().required('Old Password is required'),
  }),
  newPassword: yup.string().when('isFormOpen', {
    is: (val) => val === 'change_password',
    then: yup
      .string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(
        /[^a-zA-Z0-9]/,
        'Password must contain at least one special character'
      )
      .test(
        'newPassword',
        'Old and New Passwords should not be same',
        (value, context) => {
          return context.parent.oldPassword !== value;
        }
      ),
  }),
  confirmNewPassword: yup.string().when('isFormOpen', {
    is: (val) => val === 'change_password',
    then: yup
      .string()
      .required('Confirm new password is required')
      .test('confirmNewPassword', 'Passwords must match', (value, context) => {
        return context.parent.newPassword === value;
      }),
  }),
});
export const USER_PROFILE_QUERY = gql`
  query UserProfileQuery($where: UserFilterInput) {
    allUsers(where: $where) {
      items {
        UserId: userId
        ProfileImageUrl: profileImageUrl
        FirstName: firstName
        LastName: lastName
        EmailAddress: emailAddress
        AddressLine1: addressLine1
        AddressLine2: addressLine2
        PostCode: postCode
        City: city
        CountryId: countryId
      }
    }
  }
`;

function ProfileSettings() {
  const isMediumScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.md}px)`,
  });

  const isLargeScreen = useMediaQuery({
    query: `(min-width: ${VIEWPORT_BREAKPOINTS.lg}px)`,
  });

  const token = tokenVar();
  const authUser = useReactiveVar(authUserVar);
  const { state: locationState } = useLocation();
  const { loading: countryLoading, data: countryList } = useCountries();

  const defaultValue = locationState?.openForm ? 'profile' : '';

  const [isFormOpen, setIsFormOpen] = React.useState(defaultValue);

  const [formValues, setFormValues] = React.useState({});
  React.useEffect(() => {
    setFormValues(() => ({
      FirstName: authUser.FirstName ?? authUser.firstName,
      LastName: authUser.LastName ?? authUser.lastName,
      EmailAddress: authUser.EmailAddress ?? authUser.email,
      AddressLine1: authUser.AddressLine1 ?? authUser.addressLine1,
      AddressLine2: authUser.AddressLine2 ?? authUser.addressLine2,
      City: authUser.City ?? authUser.city,
      PostCode: authUser.PostCode ?? authUser.postalCode,
      CountryId: authUser.CountryId ?? authUser.countryId,
      isFormOpen: isFormOpen,
    }));
  }, [authUser, isFormOpen]);

  const { mutateAsync: UserProfileUpdate } = ReactQueryMutation(
    apiManager.UpdateUserProfile
  );
  const [isUpdatingProfile, setIsUpdatingProfile] = React.useState(false);

  const { loading, error, refetch } = useQuery(USER_PROFILE_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        userId: { eq: authUser.id },
      },
    },

    onCompleted: (data) => {
      // remove unwanted fields from query response: id, __typename
      const {
        __typename,
        UserId: id,
        ProfileImageUrl: profileImageUrl,
        ...rest
      } = data?.allUsers?.items[0];

      setFormValues(() => {
        const formValues = {};

        for (const [key, value] of Object.entries(rest)) {
          formValues[key] = value === null ? '' : value;
        }

        return formValues;
      });

      setAuthUser({ ...authUser, ...rest, profileImageUrl });
    },
  });

  const {
    isLoading: changePasswordLoading,
    data,
    mutate: updateUserPassword,
  } = ReactQueryMutation(apiManager.ChangeOldPassword, {
    onSuccess: (data) => {
      if (data) {
        toaster.success(data.message);
        setIsFormOpen('');
      }
    },
    onError: (error) => {
      toaster.danger(error);
    },
  });

  // React.useEffect(() => {
  //   if (data) {
  //     toaster.success(data.change_old_password.message);
  //     setIsFormOpen('');
  //   }
  // }, [data]);

  if (loading) {
    return <LoadingStateSpinner />;
  }

  if (error) {
    return <ErrorStateMsg msg={getErrorMessage(error)} />;
  }

  async function handleUserProfileUpdate(values) {
    try {
      setIsUpdatingProfile(true);
      const { data, message } = await UserProfileUpdate({
        // ...values,
        firstName: values.FirstName,
        lastName: values.LastName,
        emailAddress: values.EmailAddress,
        countryId: values.CountryId,
        addressLine1: values.AddressLine1,
        addressLine2: values.AddressLine2,
        postCode: values.PostCode,
        city: values.City,
        userId: authUser.id,
        isapplogin: false,
      });

      if (data) {
        setIsFormOpen(false);
        refetch();
        // remove unwanted fields from query response: id, __typename, hostId, isEmailVerified
        const { __typename, ...updatedUserProfile } = data;
        setAuthUser({
          ...authUser,
          ...updatedUserProfile,
          ...capitalizeKeys(data),
        });
        toaster.success('Profile updated successfully');
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsUpdatingProfile(false);
    }
  }

  const handleSubmit = (values) => {
    const {
      newPassword,
      confirmNewPassword,
      oldPassword,
      isFormOpen,
      ...reset
    } = values;
    if (isFormOpen === 'profile') {
      handleUserProfileUpdate(reset);
    } else {
      updateUserPassword({
        email: reset.EmailAddress,
        newPassword,
        confirmNewPassword,
        oldPassword,
      });
    }
  };

  if (authUser.status !== 'ACTIVE') {
    return <ActivateYourAccountMessage />;
  }

  return (
    <>
      <Pane
        paddingY={majorScale(3)}
        paddingX={majorScale(4)}
        marginBottom={majorScale(5)}
        border="1px solid var(--gray-default-color)"
        borderRadius={5}
      >
        <ProfileImageContainer
          isButtonDisabled={isUpdatingProfile}
          onButtonClick={setIsFormOpen}
          isFormOpen={isFormOpen}
          formValues={formValues}
        />
      </Pane>

      {!!isFormOpen && (
        <Formik
          enableReinitialize
          validationSchema={PROFILE_FORM_SCHEMA}
          initialValues={formValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Pane
                    display="flex"
                    flexDirection={isLargeScreen ? 'row' : 'column'}
                  >
                    {isFormOpen === 'profile' && (
                      <Pane flex="1 1 60%">
                        <Pane
                          is="section"
                          marginY={minorScale(4)}
                          paddingX={minorScale(8)}
                        >
                          <Heading
                            paddingBottom={minorScale(4)}
                            marginBottom={minorScale(4)}
                            fontSize="1em"
                            color="var(--black-light-color)"
                            borderBottom="1px solid var(--gray-lightest-color)"
                          >
                            Personal Information
                          </Heading>

                          <Pane className="row">
                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="First Name"
                                name="FirstName"
                                placeholder="Enter first name"
                                value={values.FirstName}
                                isInvalid={
                                  touched.FirstName && !!errors.FirstName
                                }
                                validationMessage={
                                  touched.FirstName && errors.FirstName
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Last Name"
                                name="LastName"
                                placeholder="Enter last name"
                                value={values.LastName}
                                isInvalid={
                                  touched.LastName && !!errors.LastName
                                }
                                validationMessage={
                                  touched.LastName && errors.LastName
                                }
                                onChange={handleChange}
                              />
                            </Pane>
                          </Pane>
                        </Pane>

                        <Pane
                          is="section"
                          marginY={minorScale(4)}
                          paddingX={minorScale(8)}
                        >
                          <Heading
                            paddingBottom={minorScale(4)}
                            marginBottom={minorScale(4)}
                            fontSize="1em"
                            color="var(--black-light-color)"
                            borderBottom="1px solid var(--gray-lightest-color)"
                          >
                            Contact Information
                          </Heading>

                          <Pane className="row">
                            <Pane className="col-12 col-md-6">
                              <FormInput
                                disabled
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Email"
                                inputMode="email"
                                name="EmailAddress"
                                placeholder="Enter Email Address"
                                value={values.EmailAddress}
                                isInvalid={
                                  touched.EmailAddress && !!errors.EmailAddress
                                }
                                validationMessage={
                                  touched.EmailAddress && errors.EmailAddress
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Field name="CountryId">
                              {({ field, meta, form }) => {
                                return (
                                  <Pane
                                    className="col-12 col-md-6"
                                    marginBottom={majorScale(2)}
                                  >
                                    <FormSelectBox
                                      height={majorScale(5)}
                                      label="Country"
                                      placeholder="Select country"
                                      options={optionsToReactSelectFormat(
                                        countryList?.CountryDetails
                                          ?.CountryDetails,
                                        {
                                          label: 'CountryDescription',
                                          value: 'CountryId',
                                        }
                                      )}
                                      isLoading={countryLoading}
                                      name={field.name}
                                      value={field.value}
                                      isInvalid={meta.touched && !!meta.error}
                                      validationMessage={
                                        meta.touched && meta.error
                                      }
                                      onChange={(selected) => {
                                        form.setFieldValue(
                                          field.name,
                                          selected.value
                                        );
                                      }}
                                    />
                                  </Pane>
                                );
                              }}
                            </Field>

                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Address Line 1"
                                name="AddressLine1"
                                placeholder="Address Line 1"
                                value={values.AddressLine1}
                                isInvalid={
                                  touched.AddressLine1 && !!errors.AddressLine1
                                }
                                validationMessage={
                                  touched.AddressLine1 && errors.AddressLine1
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Address Line 2"
                                name="AddressLine2"
                                placeholder="Address Line 2"
                                value={values.AddressLine2}
                                isInvalid={
                                  touched.AddressLine2 && !!errors.AddressLine2
                                }
                                validationMessage={
                                  touched.AddressLine2 && errors.AddressLine2
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="City"
                                name="City"
                                placeholder="Enter City"
                                value={values.City}
                                isInvalid={touched.City && !!errors.City}
                                validationMessage={touched.City && errors.City}
                                onChange={handleChange}
                              />
                            </Pane>
                            <Pane className="col-12 col-md-6">
                              <FormInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Postal Code"
                                name="PostCode"
                                placeholder="Enter PostCode"
                                value={values.PostCode}
                                isInvalid={
                                  touched.PostCode && !!errors.PostCode
                                }
                                validationMessage={
                                  touched.PostCode && errors.PostCode
                                }
                                onChange={(e) => {
                                  const value = e.target.value?.slice(0, 10);
                                  setFieldValue('PostCode', value);
                                }}
                              />
                            </Pane>
                          </Pane>
                        </Pane>
                      </Pane>
                    )}

                    {isFormOpen === 'change_password' && (
                      <Pane flex="1 1 60%">
                        <Pane
                          is="section"
                          marginY={minorScale(4)}
                          paddingX={minorScale(8)}
                        >
                          <Heading
                            paddingBottom={minorScale(4)}
                            marginBottom={minorScale(4)}
                            fontSize="1em"
                            color="var(--black-light-color)"
                            borderBottom="1px solid var(--gray-lightest-color)"
                          >
                            Change Password
                          </Heading>

                          <Pane className="row">
                            <Pane className="col-12 col-md-4">
                              <FormPasswordInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Old Password"
                                name="oldPassword"
                                placeholder="Enter Old Password"
                                value={values.oldPassword}
                                isInvalid={
                                  touched.oldPassword && !!errors.oldPassword
                                }
                                validationMessage={
                                  touched.oldPassword && errors.oldPassword
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Pane className="col-12 col-md-4">
                              <FormPasswordInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="New Password"
                                name="newPassword"
                                placeholder="Enter New Password"
                                value={values.newPassword}
                                isInvalid={
                                  touched.newPassword && !!errors.newPassword
                                }
                                validationMessage={
                                  touched.newPassword && errors.newPassword
                                }
                                onChange={handleChange}
                              />
                            </Pane>

                            <Pane className="col-12 col-md-4">
                              <FormPasswordInput
                                marginBottom={majorScale(2)}
                                inputHeight={majorScale(5)}
                                label="Confirm Password"
                                name="confirmNewPassword"
                                placeholder="Enter Confirm Password"
                                value={values.confirmNewPassword}
                                isInvalid={
                                  touched.confirmNewPassword &&
                                  !!errors.confirmNewPassword
                                }
                                validationMessage={
                                  touched.confirmNewPassword &&
                                  errors.confirmNewPassword
                                }
                                onChange={handleChange}
                              />
                            </Pane>
                          </Pane>
                          <Pane
                            className="row"
                            marginRight="0 !important"
                            marginLeft="0 !important"
                          >
                            <UnorderedList className="col-12">
                              <ListItem
                                icon={<TickCircleIcon />}
                                iconColor={
                                  values.newPassword &&
                                  /[A-Z]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                color={
                                  values.newPassword &&
                                  /[A-Z]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                fontSize="1em"
                              >
                                Uppercase Character
                              </ListItem>

                              <ListItem
                                icon={<TickCircleIcon />}
                                iconColor={
                                  values.newPassword &&
                                  /[a-z]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                color={
                                  values.newPassword &&
                                  /[a-z]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                fontSize="1em"
                              >
                                Lowercase Character
                              </ListItem>

                              <ListItem
                                icon={<TickCircleIcon />}
                                iconColor={
                                  values.newPassword &&
                                  /[0-9]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                color={
                                  values.newPassword &&
                                  /[0-9]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                fontSize="1em"
                                marginBottom="0"
                              >
                                One Number
                              </ListItem>

                              <ListItem
                                icon={<TickCircleIcon />}
                                iconColor={
                                  values.newPassword &&
                                  /[^a-zA-Z0-9]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                color={
                                  values.newPassword &&
                                  /[^a-zA-Z0-9]/.test(values.newPassword)
                                    ? 'success'
                                    : 'muted'
                                }
                                fontSize="1em"
                              >
                                One Special Character
                              </ListItem>

                              <ListItem
                                icon={<TickCircleIcon />}
                                iconColor={
                                  values.newPassword &&
                                  values.newPassword.length >= 8
                                    ? 'success'
                                    : 'muted'
                                }
                                color={
                                  values.newPassword &&
                                  values.newPassword.length >= 8
                                    ? 'success'
                                    : 'muted'
                                }
                                fontSize="1em"
                              >
                                8 Characters Minimum
                              </ListItem>
                            </UnorderedList>
                          </Pane>
                        </Pane>
                      </Pane>
                    )}
                  </Pane>

                  <Container maxWidth={isMediumScreen ? 360 : '100%'}>
                    <Btn
                      marginTop={majorScale(4)}
                      width="100%"
                      type="submit"
                      fontSize="1em"
                      textTransform="uppercase"
                      isLoading={isUpdatingProfile || changePasswordLoading}
                    >
                      Save changes
                    </Btn>
                  </Container>
                </form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default ProfileSettings;
