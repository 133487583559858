import axios from 'axios';
import get from 'lodash/get';

const Endpoints = {
  GetSiteMaintenanceStatus: '/Utility/GetSiteMaintenanceStatus',
  VerifySuperAffiliateUser: '/Affiliate/VerifySuperAffiliateUser',
};

const graphqlQuery = `
query GetMaintenanceQuery {
  siteMaintenance(take: 1) {
    siteMaintenance: items {
      id
      status
    }
  }
}
`;

const Instance = axios.create({
  baseURL: process.env.REACT_APP_COKOBAR_ENDPOINT,
  timeout: 200000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

const controller = new AbortController();
const signal = controller.signal;

// Request Interceptor
Instance.interceptors.request.use(
  async (config) => {
    // Adding the abort controller signal to the request
    config.signal = signal;

    // console.warn(`[Request] ${config.url}: `, JSON.stringify(config.data));
    return config;
  },
  (error) => {
    return Promise.reject(error.message);
  }
);

// Response Interceptor
Instance.interceptors.response.use(
  (response) => {
    if (response.data?.responseType === 'Error') {
      return Promise.reject(response.data.message);
    }
    return response.data;
  },
  (error) => {
    const data = error.response?.data;
    let errorMessage = '';
    if (data) {
      if (data.data && Array.isArray(data.data) && data.data.length > 0) {
        const errorMessageArray =
          data.data.map((currentValue) => currentValue.message) || [];
        errorMessage = errorMessageArray.join('\n');
      } else {
        const errorMag = data.error.Message ? data.error.Message : data.message;
        errorMessage = errorMag;
      }
    } else {
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  }
);

async function GetSiteMaintenanceStatus(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetSiteMaintenanceStatus, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function VerifySuperAffiliateUser(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.VerifySuperAffiliateUser, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function maintenanceGraphql() {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_GRAPHQL_ENDPOINT_V2, {
        query: graphqlQuery,
      })
      .then((response) => {
        if (response?.data?.errors?.length > 0) {
          reject(response);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const maintenanceStatusCheckapiCall = async () => {
  try {
    const [restApiResponse, graphqlResponse] = await Promise.all([
      GetSiteMaintenanceStatus(),
      maintenanceGraphql(),
    ]);
    const isRestApiMaintenance = get(restApiResponse, 'data', false);
    const isGraphqlMaintenance = get(
      graphqlResponse,
      'data.data.siteMaintenance.siteMaintenance[0].status',
      false
    );
    if (isRestApiMaintenance && isGraphqlMaintenance) {
      window.location.reload();
    }
  } catch (error) {
    window.location.reload();
  }
};

export {
  maintenanceGraphql,
  GetSiteMaintenanceStatus,
  maintenanceStatusCheckapiCall,
  VerifySuperAffiliateUser,
};
