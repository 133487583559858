import { useEffect } from 'react';
import { toaster } from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import { get } from 'lodash';

import { authUserVar } from '../helpers/auth';
import { handleLogout } from '../helpers/auth';
import clientStorage from '../libs/client-storage';
import { TICKETSIR_AUTH_USER_TOKEN } from '../constants';
import { isJwtTimeExpired } from '../helpers/functions';

const useTokenExpiry = () => {
  const authUser = useReactiveVar(authUserVar);

  useEffect(() => {
    const exp = get(authUser, 'exp');
    const checkTokenExpiry = () => {
      if (
        !!clientStorage.getItem(TICKETSIR_AUTH_USER_TOKEN) &&
        isJwtTimeExpired(exp)
      ) {
        handleLogout();
        toaster.notify('Your session has expired. Please login again.', {
          id: 'session_expired',
        });
      }
    };

    // Check token on hook initialization
    checkTokenExpiry();

    // Calculate the time remaining until the token expires
    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = (exp - currentTimeSeconds) * 1000; // Convert to milliseconds

    // Set up a timeout to check token expiry when it is about to expire
    if (timeUntilExpiry > 0) {
      const timeout = setTimeout(checkTokenExpiry, timeUntilExpiry);

      // Clean up the timeout on component unmount
      return () => clearTimeout(timeout);
    } else {
      // If the token has already expired, handle logout immediately
      checkTokenExpiry();
    }
  }, [authUser]);

  return { authUser }; // Return the current token and authUser if needed
};

export default useTokenExpiry;
