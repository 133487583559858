import * as React from 'react';
import { Pane, toaster } from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import { useMutation as ReactUseMutation } from 'react-query';
import AuthModal from '../auth/auth-modal/auth-modal';
import { EXPIRED_JWT_ERROR_MESSAGE } from '../../constants';
import { isAuthVar, tokenVar, authUserVar } from '../../helpers/auth';
import { ReactComponent as LikeIcon } from '../../assets/icons/heart.svg';
import { ReactComponent as FilledLikeIcon } from '../../assets/icons/filled-heart.svg';
import apiManager from '../../helpers/apiManager';

function FavoriteEvent({ eventItem, onSuccess }) {
  const token = tokenVar();
  const isAuth = useReactiveVar(isAuthVar);
  const authUser = useReactiveVar(authUserVar);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isEventLikedByUser, setIsEventLikedByUser] = React.useState(
    () => !!eventItem.isEventLikedByUser
  );

  React.useEffect(() => {
    setIsEventLikedByUser(() => !!eventItem.isEventLikedByUser);
  }, [isAuth, authUser.id, eventItem?.isEventLikedByUser]);

  const [isLoading, setIsLoading] = React.useState(false);

  const { mutateAsync: eventFavoriteMutate } = ReactUseMutation(
    apiManager.InsertUserEvent
  );

  async function handleEventFavoriting() {
    try {
      setIsEventLikedByUser(true);

      setIsLoading(true);
      const { data } = await eventFavoriteMutate({
        UserId: authUser.id,
        EventId: eventItem.EventId,
      });

      if (data) {
        onSuccess && onSuccess();
      }

      // if (data) {
      // setIsEventLikedByUser(true);
      //   toaster.success(
      //     `${data.insert_user_liked_events_one.event.name} added to wishlist`
      //   );
      // }
    } catch (error) {
      setIsEventLikedByUser(false);

      if (error === EXPIRED_JWT_ERROR_MESSAGE) {
        setIsModalOpen(true);
        // to refetch the homepage events
        onSuccess && onSuccess();
      } else {
        toaster.danger(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const { mutateAsync: eventUnfavoriteMutate } = ReactUseMutation(
    apiManager.DeleteUserEvent
  );

  async function handleEventUnfavoriting() {
    try {
      setIsEventLikedByUser(false);

      setIsLoading(true);
      const { data } = await eventUnfavoriteMutate({
        userId: authUser.id,
        eventId: eventItem.EventId,
      });

      if (data) {
        onSuccess && onSuccess();
      }

      // if (data) {
      //   setIsEventLikedByUser(false);
      //   toaster.success(
      //     `${data.delete_user_liked_events.returning[0].event.name} removed from wishlist`
      //   );
      // }
    } catch (error) {
      setIsEventLikedByUser(true);

      if (error === EXPIRED_JWT_ERROR_MESSAGE) {
        setIsModalOpen(true);
        // to refetch the homepage events
        onSuccess && onSuccess();
      } else {
        toaster.danger(error);
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleIconClick(e) {
    e.preventDefault();

    if (!isAuth) {
      setIsModalOpen(true);
      return;
    }

    if (isLoading) {
      return;
    }

    handleEventAction();
  }

  function handleEventAction() {
    if (isEventLikedByUser) {
      handleEventUnfavoriting();
    } else {
      handleEventFavoriting();
    }
  }

  const HeartIcon = isEventLikedByUser ? FilledLikeIcon : LikeIcon;

  return (
    <>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="inherit"
      >
        <HeartIcon cursor="pointer" onClick={handleIconClick} />
      </Pane>

      {isModalOpen && (
        <AuthModal
          isOpen={isModalOpen}
          callback={handleEventAction}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
}

export default FavoriteEvent;
