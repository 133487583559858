import * as React from 'react';
import AppleLogin from 'react-apple-login';
import { gql, useMutation, makeVar } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { toaster, minorScale, majorScale, Text } from 'evergreen-ui';

import Btn from '../../../components/btn/btn';
import { getErrorMessage } from '../../../helpers/functions';
import { UserIpAddressContext } from '../../../providers/user-ipaddress';
import { parseJwt, setAuthUser, setIsAuth } from '../../../helpers/auth';
import { ReactComponent as AppleIcon } from '../../../assets/icons/apple-store.svg';
import { SuperAffiliateContext } from '../../../providers/super-affiliate';
import apiManager from '../../../helpers/apiManager';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const isfollowAppleUser = makeVar(false);

export const setIsfollowAppleUser = (value) => {
  isfollowAppleUser(value);
};

const AppleAuth = ({
  callback,
  isSignup,
  followhost,
  isFollow,
  isPromoters,
  setIsShown,
  isPromotersUser,
  isFollowHostIncluded,
  CompanyId,
  refetch,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { superAffiliate } = React.useContext(SuperAffiliateContext);
  const { state: locationState } = useLocation();
  const { id } = useParams();
  const currentUrl = window.location.href;
  const { getLocationId } = React.useContext(UserIpAddressContext);

  const history = useHistory();

  const handleAppleAuth = async (response) => {
    try {
      setIsLoading(true);

      if (response?.authorization?.id_token) {
        const { authorization, user } = response;
        const decodedJWT = parseJwt(authorization.id_token);

        const { email, given_name, family_name } = decodedJWT;
        const { data } = await apiManager.AppleAuth({
          accessToken: authorization?.id_token,
          email: user?.email || email,
          SuperCompanyId: superAffiliate.superAffiliateUserId,
          isapplogin: false,
        });

        if (data) {
          const accessToken = data.token;
          const authUser = parseJwt(accessToken);

          if (accessToken) {
            apiManager.setToken(accessToken);
            setIsAuth(accessToken);

            const appleAuthUser = authUser && data.isPopUp;

            setAuthUser({ ...authUser, appleAuthUser });

            if (authUser.country) {
              getLocationId(authUser.country);
            }

            toaster.success(
              isSignup ? 'Account successfully created.' : 'Signin successful.'
            );
            if (callback) {
              callback();
              return;
            }

            if (authUser.country) {
              getLocationId(authUser.country);
            }

            if (locationState?.redirectTo) {
              history.push(locationState.redirectTo);
            } else if (isFollow && appleAuthUser) {
              setIsfollowAppleUser({
                setIsfollowAppleUser: true,
                CompanyId: CompanyId,
                refetch: refetch,
              });
            } else if (isFollow) {
              followhost(authUser?.id);
            } else if (isPromoters) {
              setIsShown(true);
            } else if (isPromotersUser) {
              setIsShown(true);
            } else if (!authUser.addressLine1) {
              toaster.warning('Please fill your Address Details');

              history.push({
                pathname: '/account/profile',
                state: { openForm: true },
              });
            } else {
              history.push('/account/bookings');
            }
          }
        }
      }
    } catch (error) {
      toaster.danger(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppleLogin
        usePopup
        clientId={process.env.REACT_APP_APPLE_CLIENT_ID || ''}
        redirectURI={
          currentUrl.includes('www')
            ? process.env.REACT_APP_APPLE_REDIRECT_URL
            : process.env.REACT_APP_APPLE_REDIRECT_URL_WITHOUT_WWW
        }
        scope="name email"
        callback={handleAppleAuth}
        render={(renderProps) => (
          <Btn
            width="100%"
            minWidth="130px"
            fontSize="1em"
            type="button"
            look="tertiary-outlined"
            alignItems="center"
            justifyContent="center"
            height={majorScale(6)}
            columnGap={minorScale(2)}
            marginBottom={majorScale(3)}
            isLoading={isLoading}
            onClick={renderProps.onClick}
          >
            <AppleIcon width={24} />
            <Text fontWeight="bold">Apple</Text>
          </Btn>
        )}
      />
    </>
  );
};

export default AppleAuth;
